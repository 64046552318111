<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Proyectos de Sociedades
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro Proyectos de Sociedades
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre Investigacion</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model=articulo.noimbre_investigacion>
                                    </div>

                                    <div class="form-group">
                                        <label>Sociedad</label>
                                        <select class="form-control" v-model=articulo.sociedades_id>
                                            <option v-for="art in article" :key="art.id" :value="art.id">
                                                {{ art.nombre_soce}}
                                            </option>
                                        </select>

                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveArticulo()">Registrar</button>
                                        <button class="btn btn-danger" @click="backArticulo()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            article:null,
            articulo:{
                noimbre_investigacion:'',
                sociedades_id:'',
            }
        }
    },
    mounted(){
        this.onLoadArticulo()
    },
    methods:{
        async onLoadArticulo(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/listadoInv')
            this.article=data
        },
        async saveArticulo(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/crear',this.articulo).
            then(res =>console.log(res))
            this.$router.push('/admin/index-proyectosSoce');
        },
        backArticulo(){
        this.$router.push('/admin/index-proyectosSoce');
    }
    }
}
</script>
