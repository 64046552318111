<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <form action="#login" class="Form" @submit.prevent="Userlogin">
        <div class="contenedor">
          <img src="../../../public/img/logo_dicYt.jpg" class="imalog" alt="">
          <p class="Form-description">Dirección de Investigación Científica y Tecnológica</p>
        </div>

        <div class="Form-fields">
            <input type="email" id="email" v-model="login.email" class="ControlInput" placeholder="Introduce tu correo electrónico" required>
            <label for="email" class="Control-label Control-label--email">Correo Electrónico</label>
            <div class="Control-requirements Control-requirements--email">
              Debe ser un correo electrónico válido
            </div>
              <!-- esto está aquí para darle poder al selector CSS marcado -->
              <input type="checkbox" id="show-password" class="show-password">

              <label for="show-password" class="Control-label Control-label--showPassword">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 60" width="32" height="32" class="svg-toggle-password" title="Toggle Password Security">
                  <title>Ocultar/Ver Contraseña</title>
                  <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z"/>
                    <rect x="20.133" y="2.117" height="44" transform="translate(23.536 -8.587) rotate(45)" class="closed-eye"/>
                    <rect x="22" y="3.984" width="4" height="44" transform="translate(25.403 -9.36) rotate(45)" style="fill:#fff" class="closed-eye"/>
                </svg>
              </label>
              <label for="password" class="Control-label">Contraseña</label>
              <input type="password" id="password" placeholder="Ingresa tu contraseña" class="ControlInput" v-model="login.password">

              <button type="submit" class="Button Form-submit">
                Iniciar Sesion
              </button>


        </div>
      </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data(){
            return {
                login:{
                    email:null,
                    password:null
                }
            }
        },
        methods: {
    async Userlogin() {
      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/usuarios/login', {
          email: this.login.email,
          password: this.login.password
        });
        localStorage.setItem('token',response.data.token);
        this.$router.push('/admin');
      } catch (error) {
        alert('Login failed');
      }
    }
    }
}
</script>
<style scoped>
.contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imalog {
    width: 100px;
    height: 100px;
    box-shadow: -5px 5px 15px grey;
}

.Form {
    width: 100%;
    max-width: 400px;
    margin: auto;
}

.Form-title {
    color: #00386a;
    margin: 0 0 15px;
}

.Form-description {
    color: rgba(109, 102, 101, 0.5);
}

.Form-fields {
    border-radius: 15px;
    border: 1px solid #c5c1c0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 24px 15px;
}

.Control-label {
    color: #423e3d;
    font-weight: bold;
    transition: color 300ms ease;
}

.Control-requirements {
    display: none;
    background: pink;
    border-top: none;
    margin: -14px 0 15px;
    max-height: 0;
    overflow: hidden;
    transition: all 200ms ease;
    padding: 0 9px;
}

.ControlInput {
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(197, 193, 192, 0) inset;
    color: #6d6665;
    margin: 0.8em 0;
    border: 1px solid #c5c1c0;
    padding: 0.6em 0.6em;
    transition: box-shadow 300ms ease-out;
}

.ControlInput:hover {
    box-shadow: 0 1px 3px rgba(197, 193, 192, 0.4) inset;
}

.ControlInput:focus {
    outline: #2bbee7 auto 3px;
}

.ControlInput:invalid:not(:focus):not(:placeholder-shown) {
    border-color: red;
    border-bottom: none;
    box-shadow: 0 1px 3px rgba(255, 0, 0, 0.03) inset;
}

.ControlInput:invalid:not(:focus):not(:placeholder-shown) + .Control-label {
    color: red;
}

.ControlInput:invalid:not(:focus):not(:placeholder-shown) + .Control-label + .Control-requirements {
    display: block;
    max-height: 100px;
    padding: 9px;
    border: 1px solid red;
}

.ControlInput:valid:not(:focus):not(:placeholder-shown) {
    border-color: rgb(16, 226, 16);
}

.ControlInput:valid:not(:focus):not(:placeholder-shown) + .Control-label {
    color: rgb(16, 226, 16);
}

.Control-requirements--email,
.ControlInput--email {
    order: -1;
}

.Control-label--email {
    order: -2;
}

.ControlInput--password {
    order: 0;
    padding-right: 50px;
}

.Control-label--password {
    order: -1;
    margin-bottom: -32px;
}

.Control-label--showPassword {
    width: 32px;
    position: relative;
    top: 52px;
    left: 100%;
    text-align: right;
    margin-left: -36px;
}

.Form-submit {
    border: 1px solid #e7542b;
    background-color: #e7542b;
    border-radius: 3px;
    color: #fff;
    width: 100%;
    max-width: 100%;
}

.Form-submit:hover {
    background-color: darken(#e7542b, 10%);
}

.ControlInput--email:valid ~ .ControlInput--password:valid ~ .Form-submit {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

.Button-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -13px;
    margin-top: -13px;
    opacity: 0;
}

.show-password {
    display: none;
}

.show-password:checked {
    background: purple;
}

.show-password:checked ~ .ControlInput--password {
    text-security: disc;
    -webkit-text-security: disc;
    -moz-text-security: disc;
}

.show-password:checked ~ .Control-label--showPassword .svg-toggle-password .closed-eye {
    opacity: 1;
    transition: opacity 300ms ease, height 400ms ease;
    width: 4px;
    height: 44px;
}

.svg-toggle-password {
    fill: rgba(0, 142, 214, 0.5);
}

.closed-eye {
    opacity: 0;
    height: 0;
}

.Button {
    padding: 10px;
}

.spinner {
    animation: dash 2s linear infinite;
    animation-direction: normal;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 0;
        stroke-dasharray: 150.6 100.4;
    }
    50% {
        stroke-dasharray: 1 250;
    }
    100% {
        stroke-dashoffset: 502;
        stroke-dasharray: 150.6 100.4;
    }
}

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    background-color: #f1f2f1;
    background-image: linear-gradient(#e7e8e7, #f1f2f1);
    display: flex;
    min-height: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}
</style>
