<template>
    <div>
         <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Direcciones de investigación</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/investigacion-index">Investigacion en la UTO</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Direcciones de investigación</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

    <div class="container">
      <div class="row">
        <h2 class="text-secondary text-center mb-5 pt-3">Direcciones</h2>
      </div>

      <div class="row">
        <div
          class="col-lg-3 col-md-4 col-sm-6 pb-2"
          v-for="inv in direcciones"
          :key="inv.id"
        >
          <div class="team-item bg-white mb-4">
            <div class="team-img position-relative overflow-hidden">
              <GoogleDriveViewer
                :driveUrl="inv.imagen"
                class="img-fluid w-100"
              />
            </div>
            <div class="text-center pb-5 pt-5">
              <h5 class="text-truncate" style="text-wrap: wrap">
                {{ inv.nombre}}
              </h5>
              <br />
              <p class="m-0">{{ inv.descripcion }}</p>
              <p class="m-0">{{ inv.contacto }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import GoogleDriveViewer from "@/components/helpers/GoogleDriveViewer"; // Ruta al componente GoogleDriveViewer.vue
import axios from 'axios';
    export default {
      components: {
    GoogleDriveViewer,
  },
        data(){
          return {
            direcciones:null
          }
        },
        mounted() {
          this.onLoadDirecciones()
        },
        methods: {
          async onLoadDirecciones(){
            const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + "/direcciones/listado")
            this.direcciones=data
          }
        },
    }
</script>

<style scoped>
 ul{
    padding-left:0px;
  }
</style>