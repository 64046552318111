<template>
    <div>
      <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div>
          <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
          <h1>
            Editar Proyectos Sociedades
          </h1>
        </div>
              <div class="col-lg-12">
                  <div class="panel panel-default">
                      <div class="panel-heading">
                          Formulario de Registro de Proyectos de Sociedades
                      </div>
                      <div class="panel-body">
                              <form role="form">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Nombre Investigacion</label>
                                          <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model=articulosEdit.noimbre_investigacion>
                                      </div>
                                      <div class="form-group">
                                          <label>Sociedad</label>
                                          <select class="form-control" v-model=articulosEdit.sociedades_id>
                                            <option v-for="art in article" :key="art.id" :value="art.id">
                                                {{ art.nombre_soce}}
                                            </option>
                                        </select>

                                      </div>
                                      </div>
                                  </div>
                                  </form>

                      </div>

                  </div>
              </div>
          </div>
      </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditArticulos">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>
      </div>




    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1>
            Listado Proyectos de Sociedades
            <router-link to="/admin/create-proyectoSoce"
              ><button class="btn btn-success">Nuevo Proyecto Sociedad </button></router-link
            >
          </h1>
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="panel panel-default">
            <div class="panel-heading">Gestion y Registro de Proyectos de Sociedades</div>
            <!-- /.panel-heading -->
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  class="table table-striped table-bordered table-hover"
                  id="dataTables-example"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre Investigacion</th>
                    </tr>
                  </thead>
                  <tbody v-for="art in articulos" :key="art.id">
                    <tr>
                     <td>{{art.id}}</td>
                     <td>{{art.noimbre_investigacion}}</td>
                      <td class="center">
                        <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='EditArticulos(art.id)'>
                          Editar


                        </button>
                        <button class="btn btn-danger" @click="deleteArticuls(art.id)">
                        Eliminar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.table-responsive -->
            </div>
            <!-- /.panel-body -->
          </div>
          <!-- /.panel -->
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <!-- /.container-fluid -->
    </div>

    </div>

  </template>
  <script>
  import axios from 'axios'
  export default {
      data(){
          return{
              article:null,
              articulos:null,
              articulosEdit:{}
          }
      },
      mounted(){
          this.onLoadArticulos(),
          this.onLoadArticulo()
      },
      methods:{
        async onLoadArticulo(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/listadoInv')
            this.article=data
        },
          async onLoadArticulos(){
             await axios.get(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/listado').
              then(res =>{
                  const {data}=res
                  this.articulos=data
              })
          },
          async deleteArticuls(id){
         await axios.delete(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/eliminar',{
          data:{id}
        })
          this.onLoadArticulos()
      },
      async EditArticulos(id){
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/mostrar/'+id).then(res =>{
                  const {data}=res
                  this.articulosEdit=data[0]
              })
      },
      async saveEditArticulos(){
        const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/proyecto-soce/actualizar',this.articulosEdit)
        console.log(data);
        this.onLoadArticulos()
      }
      },


  }
  </script>

