<template>
     <!-- Footer Start -->
     <div class="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style="margin-top: 90px;" id="footer">
        <div class="row pt-5">
            <div class="col-lg-3 col-md-6 mb-5">
                <a href="" class="navbar-brand">
                    <img class="img-thumbnail"  style="width: 100px;" src="../../public/img/logo_dicYt.jpg" alt="logo_dicYt">
                </a>
                <p>DIRECCIÓN DE INVESTIGACIÓN CIENTÍFICA Y TECNOLÓGICA DE LA UTO</p>
                <h6 class="text-white text-uppercase mt-4 mb-3" style="letter-spacing: 5px;">Redes Sociales</h6>
                <div class="d-flex justify-content-start">
                    <a class="btn btn-outline-primary btn-square" href="https://www.facebook.com/dicyt.oruro"><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-outline-primary btn-square" href="https://www.youtube.com/@investigaciondicytuto7870"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px;">Revise</h5>
                <div class="d-flex flex-column justify-content-start">
                    <router-link class="text-white-50 mb-2" to="/"><i class="fa fa-angle-right mr-2"></i>Inicio</router-link>
                    <router-link class="text-white-50 mb-2" to="/quienes-somos"><i class="fa fa-angle-right mr-2"></i>Quienes Somos?</router-link>
                    <router-link class="text-white-50 mb-2" to="/proyectos"><i class="fa fa-angle-right mr-2"></i>Proyectos</router-link>
                    <router-link class="text-white-50 mb-2" to="/investigacion-index"><i class="fa fa-angle-right mr-2"></i>Investigacion</router-link>
                    <router-link class="text-white-50 mb-2" to="/ferias"><i class="fa fa-angle-right mr-2"></i>Ferias de Ciencias</router-link>
                    <router-link class="text-white-50 mb-2" to="/observatorio"><i class="fa fa-angle-right mr-2"></i>Observatorio</router-link>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px;">Contactos</h5>
                <p><i class="fa fa-map-marker-alt mr-2"></i>Calle Caro entre Av. 6 de agosto y Velasco Galvarro, Edificio N° 165, piso #5</p>
                <p><i class="fa fa-phone-alt mr-2"></i>52 80590</p>
                <p><i class="fa fa-envelope mr-2"></i>investigacion.uto@gmail.com</p>
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px;">Ubicación</h5>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3795.237805329269!2d-67.11085812616685!3d-17.967669880211634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93e2b1f0d927ca4b%3A0xfa8f27cce6e95fae!2sDICYT-UTO!5e0!3m2!1ses!2sbo!4v1712153596143!5m2!1ses!2sbo" width="300" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                   <img src="https://counter4.optistats.ovh/private/contadorvisitasgratis.php?c=dlyle35r5z618rf7ab4k1dplaeyaftfp" title="contador de visitas para web" alt="contador de visitas para web">
            </div>
        </div>
        
    </div>
    <div class="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style="border-color: rgba(256, 256, 256, .1) !important;">
        <div class="row" >
            <div class="col-lg-6 text-center text-md-left mb-3 mb-md-0" >
                <p class="m-0 text-white-50" id="pie" style="text-align: center;">Copyright &copy; <a href="#">Direccion de Investigacion Cientifica y Tecnologia.All Rights Reserved.</a>
                </p>
            </div>
        </div>
    </div>

    <!-- Footer End -->
</template>

<script>
    export default {
        
    }
</script>
<style lang="scss" scoped>

</style>