<template>
    <div>
  <br>
<br>
<br>
<br>
<br>
<br>
<div>
      <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
      <div class="row">
          <div class="col-lg-12">
      <h1>
        Editar Sociedades Cientificas
      </h1>
    </div>
          <div class="col-lg-12">
              <div class="panel panel-default">
                  <div class="panel-heading">
                      Formulario de Registro de Sociedades Cientificas
                  </div>
                  <div class="panel-body">
                          <form role="form">
                              <div class="row">
                                  <div class="col-lg-6">
                                      <div class="form-group">
                                      <label>Nombre Sociedad</label>
                                      <input class="form-control" placeholder="Ingresa Nombre Investigador" v-model="sociedadesEdit.nombre_soce">
                                  </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="form-group">
                                      <label>Carrera</label>
                                      <input class="form-control" placeholder="Ingresa Carrera" v-model="sociedadesEdit.carrera">
                                  </div>

                                  <div class="form-group">
                                      <label>Facultad</label>
                                      <input class="form-control" placeholder="Ingresa Facultad" v-model="sociedadesEdit.facultad">
                                  </div>
                                  <div class="form-group">
                                      <label>universitarios</label>
                                      <input class="form-control" placeholder="Ingresa Facultad" v-model="sociedadesEdit.universitarios">
                                  </div>
                                  <div class="form-group">
                                      <label>Cargo</label>
                                      <input class="form-control" placeholder="Ingresa Facultad" v-model="sociedadesEdit.cargo">
                                  </div>
                                  </div>
                              </div>
                              </form>

                  </div>

              </div>
          </div>
      </div>
  </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditConvocatoria">Guardar Cambios</button>
    </div>
  </div>
</div>
</div>
  </div>




<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <h1>
        Listado de Investigaciones y Publicaciones
        <router-link to="/admin/create-sociedad"
          ><button class="btn btn-success">Nueva Sociedad </button></router-link
        >
      </h1>
    </div>
    <!-- /.col-lg-12 -->
  </div>
  <!-- /.row -->
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-default">
        <div class="panel-heading">Gestion y Registro de Investigaciones y Publicaciones</div>
        <!-- /.panel-heading -->
        <div class="panel-body">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="dataTables-example"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre Sociedad</th>
                  <th>Universitario</th>
                  <th>Cargo</th>
                  <th>Carrera</th>
                  <th>Facultad</th>
                  <th>Investigaciones</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody v-for="inv in sociedades" :key="inv.id">
                <tr>
                 <td>{{inv.id}}</td>
                 <td>{{inv.nombre_soce}}</td>
                 <td>{{inv.universitarios}}</td>
                 <td>{{inv.cargo}}</td>
                 <td>{{ inv.carrera }}</td>
                 <td>{{ inv.facultad }}</td>
                 <td>{{ inv.proyecto_nombre }}</td>
                  <td class="center">
                    <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editInvestigadores(inv.id)'>
                      Editar

                    </button>
                    <button class="btn btn-danger" @click="deleteInvestigadores(inv.id)">
                    Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /.table-responsive -->
        </div>
        <!-- /.panel-body -->
      </div>
      <!-- /.panel -->
    </div>
    <!-- /.col-lg-12 -->
  </div>
  <!-- /.row -->
  <!-- /.container-fluid -->
</div>

</div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
      return{
          sociedades:null,
          sociedadesEdit:{}
      }
  },
  mounted(){
      this.onLoadInvestigadores()
  },
  methods:{
      async onLoadInvestigadores(){
         const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + '/sociedad/listado')
         this.sociedades=data
      },
      async deleteInvestigadores(id){
     await axios.delete(process.env.VUE_APP_API_BASE_URL + '/sociedad/eliminar',{
      data:{id}
    })
      this.onLoadInvestigadores()
  },
  async editInvestigadores(id){
    await axios.get(process.env.VUE_APP_API_BASE_URL + '/sociedad/mostrar/'+id).then(res =>{
              const {data}=res
              this.sociedadesEdit=data[0]
          })
  },
  async saveEditConvocatoria(){
    const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/sociedad/actualizar',this.sociedadesEdit)
    console.log(data);
    this.onLoadInvestigadores()
  }
  },


}
</script>

