<template>
  <div>
    <NavBar/>
    <router-view></router-view>
    <FooterBar/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';
export default {
  components :{
    NavBar,
    FooterBar
  },
  data () {
  
    return {
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>