<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Politicas
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Politicas
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model=propuestas.nombre>
                                    </div>
                                    <div class="form-group">
                                        <label>fecha</label>
                                        <input type="date" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=propuestas.fecha>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Archivo</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=propuestas.archivo>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveRepositorio()">Registrar</button>
                                        <button class="btn btn-danger" @click="backRepositorio()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            propuestas:{
                nombre:'',
                fecha:'',
                archivo:""
            }
        }
    },
    methods:{
        async saveRepositorio(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/propuestas/crear',this.propuestas).
            then(res =>console.log(res))
            this.$router.push('/admin/index-politicas');
        },
        backRepositorio(){
        this.$router.push('/admin/index-politicas');
    }
    }
}
</script>
