<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Usuarios
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Usuarios
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Usuario" v-model=usuario.nombre>
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Email" v-model=usuario.email>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Contraseña</label>
                                        <textarea class="form-control" placeholder="Ingresa Contraseña" v-model="usuario.password"> </textarea>
                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Rol</label>
                                        <textarea class="form-control" placeholder="Ingresa Contraseña" v-model="usuario.rol"> </textarea>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveUsuario()">Registrar</button>
                                        <button class="btn btn-danger" @click="backUsuario()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            usuario:{
                nombre:'',
                email:'',
                password:"",
                rol:''
            }
        }
    },
    methods:{
        async saveUsuario(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/usuarios/crear',this.usuario).
            then(res =>console.log(res))
            this.$router.push('/admin/index-usuario');
        },
        backUsuario(){
        this.$router.push('/admin/index-usuario');
    }
    }
}
</script>
