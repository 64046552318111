<template>
  <div>
    <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div>
        <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Editar Convocatorias y Eventos
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Convocatorias
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model="convocatoriasEdit.nombre">
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="convocatoriasEdit.imagen">

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Descripcion</label>
                                        <textarea class="form-control" placeholder="Ingresa la Descripcion" v-model="convocatoriasEdit.descripcion"> </textarea>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Tipo</label>
                                        <select id="options" class="form-control" v-model="convocatoriasEdit.tipo">
                                        <option value="principal">Principal</option>
                                        <option value="guardana">Guardaña</option>
                                        <option value="sulloma">Sulloma</option>
                                        <option value="fluvial">Agua Fluvial</option>
                                        <option value="residual">Agua Residual</option>
                                        </select>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="convocatoriasEdit.tipo">
                                    </div>
                                    </div>
                                </div>
                                </div>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditConvocatoria">Guardar Cambios</button>
      </div>
    </div>
  </div>
</div>
    </div>




  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h1>
          Listado Convocatorias y Eventos
          <router-link to="/admin/create-convocatorias"
            ><button class="btn btn-success">Nueva Convocatoria </button></router-link
          >
        </h1>
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-default">
          <div class="panel-heading">Gestion y Registro de Convocatorias y Eventos</div>
          <!-- /.panel-heading -->
          <div class="panel-body">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered table-hover"
                id="dataTables-example"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Imagen</th>
                    <th>Tipo</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody v-for="conv in convocatorias" :key="conv.id">
                  <tr>
                   <td>{{conv.id}}</td>
                   <td>{{conv.nombre}}</td>
                   <td>{{ conv.descripcion }}</td>
                   <td>{{ conv.imagen }}</td>
                   <td>{{ conv.tipo }}</td>
                    <td class="center">
                      <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editConvocatoria(conv.id)'>
                        Editar


                      </button>
                      <button class="btn btn-danger" @click="deleteConvocatoria(conv.id)">
                      Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.table-responsive -->
          </div>
          <!-- /.panel-body -->
        </div>
        <!-- /.panel -->
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <!-- /.container-fluid -->
  </div>

  </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            convocatorias:null,
            convocatoriasEdit:{}
        }
    },
    mounted(){
        this.onLoadConvcatorias()
    },
    methods:{
        async onLoadConvcatorias(){
           await axios.get(process.env.VUE_APP_API_BASE_URL + '/convocatorias/listado').
            then(res =>{
                const {data}=res
                this.convocatorias=data
            })
        },
        async deleteConvocatoria(id){
       await axios.delete(process.env.VUE_APP_API_BASE_URL + '/convocatorias/eliminar',{
        data:{id}
      })
        this.onLoadConvcatorias()
    },
    async editConvocatoria(id){
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/convocatorias/mostrar/'+id).then(res =>{
                const {data}=res
                this.convocatoriasEdit=data[0]
            })
    },
    async saveEditConvocatoria(){
      const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/convocatorias/actualizar',this.convocatoriasEdit)
      console.log(data);
      this.onLoadConvcatorias()
    }
    },


}
</script>

