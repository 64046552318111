<template>
    <div>
      <!-- Contenedor donde se mostrará el archivo de Google Drive -->
      <iframe :src="embedUrl" scrolling="no" frameborder="no" class="img-fluid w-100" ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      driveUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        embedUrl: ''
      };
    },
    mounted() {
      this.embedUrl = this.generateEmbedUrl(this.driveUrl);
    },
    methods: {
      // Función para obtener el ID del archivo de Google Drive desde el enlace
      getIdFromUrl(url) {
        let id;
        if (url.includes('drive.google.com')) {
          let splitUrl = url.split('/');
          id = splitUrl[splitUrl.length - 2];
        }
        return id;
      },
      // Función para generar el enlace de incrustación del archivo de Google Drive
      generateEmbedUrl(url) {
        let fileId = this.getIdFromUrl(url);
        if (fileId) {
          return 'https://drive.google.com/file/d/' + fileId + '/preview';
        } else {
          console.error('URL de Google Drive no válida');
          return '';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales para el iframe */
  iframe {
    border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  </style>