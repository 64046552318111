<template>
    <div>
         <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Proyectos concluidos</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/proyectos">Proyectos Ejecucion</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Proyectos concluidos</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

    <!-- Booking Start -->
    <div class="container-fluid booking mt-5 pb-5">
        <div class="container pb-5">
            <div class="bg-light shadow" style="padding: 30px;">
                <div class="row align-items-center" style="min-height: 60px;">
                    <div class="col">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mb-3 mb-md-0">
                                    <div class="date" id="date1" data-target-input="nearest">
                                        <input type="text" class="form-control p-4 datetimepicker-input" placeholder="Proyecto X" data-target="#date1" data-toggle="datetimepicker"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-block" type="button" style="height: 47px; margin-top: -2px;">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Booking End -->

<!-- Convocatorias start -->
<div class="container ">
    <div class="container ">
        <div class="row">
          <h2 class="text-secondary text-center mb-5 pt-3">Precipitación pluvial y evaporacion</h2>    
        </div>
    </div>
</div>
<!--courses end -->
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>