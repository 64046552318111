<template>
    <div>
      <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div>
          <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
          <h1>
            Editar Direcciones
          </h1>
        </div>
              <div class="col-lg-12">
                  <div class="panel panel-default">
                      <div class="panel-heading">
                          Formulario de Registro de Direcciones
                      </div>
                      <div class="panel-body">
                              <form role="form">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Nombre</label>
                                          <input class="form-control" placeholder="Ingresa Nombre " v-model="direccionesEdit.nombre">
                                      </div>
                                      <div class="form-group">
                                          <label>Descripcion</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Descripcion" v-model="direccionesEdit.descripcion">

                                      </div>
                                      <div class="form-group">
                                          <label>Contacto</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Contacto" v-model="direccionesEdit.contacto">
                                      </div>
                                      <div class="form-group">
                                          <label>Imagen</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="direccionesEdit.imagen">
                                      </div>
                                      </div>
                                      </div>
                                </form>

                      </div>

                  </div>
              </div>
          </div>
      </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditDirecciones">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>
      </div>




    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1>
            Listado Direcciones
            <router-link to="/admin/create-direcciones"
              ><button class="btn btn-success">Nueva Direccion </button></router-link
            >
          </h1>
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="panel panel-default">
            <div class="panel-heading">Gestion y Registro de Direcciones</div>
            <!-- /.panel-heading -->
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  class="table table-striped table-bordered table-hover"
                  id="dataTables-example"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Descripcion</th>
                      <th>Contacto</th>
                      <th>Imagen</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody v-for="feria in direcciones" :key="feria.id">
                    <tr>
                     <td>{{feria.id}}</td>
                     <td>{{feria.nombre}}</td>
                     <td>{{ feria.descripcion }}</td>
                     <td>{{ feria.contacto }}</td>
                     <td>{{ feria.imagen }}</td>
                      <td class="center">
                        <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editDireccines(feria.id)'>
                          Editar


                        </button>
                        <button class="btn btn-danger" @click="deleteInstitutos(feria.id)">
                        Eliminar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.table-responsive -->
            </div>
            <!-- /.panel-body -->
          </div>
          <!-- /.panel -->
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <!-- /.container-fluid -->
    </div>

    </div>

  </template>
  <script>
  import axios from 'axios'
  export default {
      data(){
          return{
              direcciones:null,
              direccionesEdit:{}
          }
      },
      mounted(){
          this.onLoadDirecciones()
      },
      methods:{
          async onLoadDirecciones(){
             await axios.get(process.env.VUE_APP_API_BASE_URL + '/direcciones/listado').
              then(res =>{
                  const {data}=res
                  this.direcciones=data
              })
          },
          async deleteInstitutos(id){
         await axios.delete(process.env.VUE_APP_API_BASE_URL + '/direcciones/eliminar',{
          data:{id}
        })
          this.onLoadDirecciones()
      },
      async editDireccines(id){
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/direcciones/mostrar/'+id).then(res =>{
                  const {data}=res
                  this.direccionesEdit=data[0]
              })
      },
      async saveEditDirecciones(){
        const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/direcciones/actualizar',this.direccionesEdit)
        console.log(data);
        this.onLoadDirecciones()
      }
      },


  }
  </script>

