<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Sociedades
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Sociedades
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre Sociedad</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Investigador" v-model=sociedad.nombre_soce>
                                    </div>
                                    <div class="form-group">
                                        <label>Universitario</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Nombre" v-model=sociedad.universitarios>

                                    </div>

                                    <div class="form-group">
                                        <label>Carrera</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Carrera" v-model=sociedad.carrera>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Facultad</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Facultad" v-model=sociedad.facultad>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Cargo</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Facultad" v-model=sociedad.cargo>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveInvestigacion()">Registrar</button>
                                        <button class="btn btn-danger" @click="backInvestigacion()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            sociedad:{
                nombre_soce:'',
                universitarios:'',
                cargo:'',
                carrera:"",
                facultad:""
            }
        }
    },
    methods:{
        async saveInvestigacion(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/sociedad/crear',this.sociedad).
            then(res =>console.log(res))
            this.$router.push('/admin/index-sociedad');
        },
        backInvestigacion(){
        this.$router.push('/admin/index-sociedad');
    }
    }
}
</script>
