<template>
    <div>
    <div>
            <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Proyecto de uso de aguas residuales comunales</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/proyectos">Proyectos Ejecucion</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Proyecto de uso de aguas residuales comunales</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

     <!--Information start-->
   <div class="container">
    <hr>
    <div class="row" id="myv">
        <div class="col-sm-4" style="display: flex; align-items: center;">
            <!-- Column Info 1 -->
            <img class="img-thumbnail"  src="../../../public/img/Aguas residuales.jpg" alt="" style="height: 300px; width: 300px;">
        </div>
        <div class="col-sm-8">
            <!-- Column Info 1 -->
            <h2 class="text-primary text-center ">Misión y Visión</h2>
            <h5>
                Misión
            </h5>
            <p class="text-wrap" style="text-align: justify;">
                La cuenca pedagógica Guardaña presenta las deficiencias en la gestión de sus recursos naturales. biodiversidad y ambiental por la escasa información hacia las familias.
            </p>
            <hr>
            <h5>
                Visión
            </h5>
            <p class="text-wrap" style="text-align: justify;">
                Ser una cuenca pedagógica de enseñanza y manejo sustentable de recursos naturales. biodiversidad y medio ambiental, mediante la concientización.
            </p>
            <hr>
        </div>
    </div>
</div>
<!--Information end-->
<!-- Convocatorias start -->
<div class="container">
  <h2 class="text-primary pb-3 pt-3 text-uppercase text-center">Responsables</h2>
  <div class="row">
    <div
          class="col-lg-3 col-md-4 col-sm-6 pb-2"
          v-for="inv in ResponsablesAguaFluvial"
          :key="inv.id"
        >
          <div class="team-item bg-white mb-4">
            <div class="team-img position-relative overflow-hidden">
              <GoogleDriveViewer
                :driveUrl="inv.imagen"
                class="img-fluid w-100"
              />
            </div>
            <div class="text-center pb-5 pt-5">
              <h5 class="text-truncate" style="text-wrap: wrap">
                {{ inv.nombre }}
              </h5>
              <br />
              <p class="m-0">{{ inv.cargo }}</p>
            </div>
          </div>
        </div>
    </div>

</div>
<div class="container ">
    <h2 class="text-primary pb-3 pt-3 text-uppercase text-center">Convocatorias</h2>
    <div class="row"  v-for="guardana in AguaFluvial" :key="guardana.id">
        <div class="col-sm-4">
          <GoogleDriveViewer
                :driveUrl="guardana.imagen"
                class="img-fluid w-100"
              />
        </div>
        <div class="col-sm-8">
            <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ guardana.nombre }}</strong></a>
            <p style="text-align:justify ;">
               {{ guardana.descripcion }}
            </p>
            <hr>
        </div>
    </div>
<!-- Convocatorias end -->

<!-- news start -->
<div class="row bg-registration">
  <h2 class="m-4 text-primary text-center text-uppercase" >
            Anuncios
  </h2>
      <div class="col-sm-12" v-for="aun in AnunciosAguaFluvial" :key="aun.id">
        <!-- ActividadesAguaFluvial Info 1 -->
        <GoogleDriveViewer
                :driveUrl="aun.imagen"
                class="img-fluid w-50"
              />
        <h5 style="color:white">
            {{ aun.titulo }}
        </h5>
        <button class="btn btn-primary"><i class="fa fa-book" aria-hidden="true"></i>  Ingresar</button>
        <hr>
      </div>
    </div>
<!--news end -->

<!--courses start -->
    <h2 class="text-secondary text-center mb-5 pt-5">Actividades realizadas</h2>
    <div class="accordion" id="accordion">
        <div class="card" v-for="act in ActividadesGuardana" :key="act.id">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{ act.nombre }}
              </button>
            </h2>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">

              {{ act.descripcion }}

                <GoogleDriveViewer
                :driveUrl="act.imagen1"
                class="img-fluid w-50"
              />

                <GoogleDriveViewer
                :driveUrl="act.imagen2"
                class="img-fluid w-50"
              />
                <GoogleDriveViewer
                :driveUrl="act.imagen3"
                class="img-fluid w-50"
              />

            </div>
          </div>
        </div>
      </div>
<!--courses end -->
</div>
    </div>
    </div>
</template>

<script>
import GoogleDriveViewer from "@/components/helpers/GoogleDriveViewer"; // Ruta al componente GoogleDriveViewer.vu
  import axios from 'axios';
    export default {
      components: {
    GoogleDriveViewer,
  },
        data(){
          return {
            AguaFluvial:null,
            AnunciosAguaFluvial:null,
            ActividadesAguaFluvial:null,
            ResponsablesAguaFluvial:null

          }
        },
        mounted(){
          this.onLoadAguaFluvialConvocatorias(),
          this.onLoadAguaFluvialActividades(),
          this.onLoadAguaFluvialAnuncios()
          this.onLoadAguaFluvialResponsables()
        },
        methods:{
          async onLoadAguaFluvialConvocatorias(){
            const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + "/proyecto_ejecucion_fluvial/listado-convocatoria-fluvial")
            this.AguaFluvial=data
          },
          async onLoadAguaFluvialAnuncios(){
            const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + "/proyecto_ejecucion_fluvial/listado-anuncios-fluvial")
            this.AnunciosAguaFluvial=data  }
        ,
          async onLoadAguaFluvialActividades(){
            const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + "/proyecto_ejecucion_fluvial/listado-actividades-fluvial")
            this.ActividadesAguaFluvial=data
          },
          async onLoadAguaFluvialResponsables(){
            const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + "/proyecto_ejecucion_fluvial/listado-responsable-fluvial")
            this.ResponsablesAguaFluvial=data
          }

        }
    }
</script>

<style lang="scss" scoped>

</style>
