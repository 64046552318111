<template>
    <div>
           <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Artículos publicados en revistas indexadas</h3>
                <div class="d-inline-flex text-white">
                  <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/investigacion-index">Investigacion en la UTO</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Artículos publicados en revistas indexadas</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

<div class="container ">
    <div class="row">
      <h2 class="text-secondary text-center mb-5 pt-3">Ciencias de la ingeniería y tecnología</h2>    
    </div>
    <div class="row">
      <h2 class="text-secondary text-center mb-5 pt-3">Ciencias de la salud</h2>    
    </div>
    <div class="row">
      <h2 class="text-secondary text-center mb-5 pt-3">Informe de ejecución financiera 2024</h2>    
    </div>
   
</div>
    </div>
</template>
<script>
export default {
    
}
</script>
