<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Personal Administrativo
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Personal Administrativo
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa C.I." >

                                    </div>
                                    <div class="form-group">
                                        <label>Apellidos</label>
                                        <input class="form-control" placeholder="Ingresa tus Nombres">

                                    </div>
                                    <div class="form-group">
                                        <label>Cargo</label>
                                        <input class="form-control" placeholder="Ingresa tus Apellidos">

                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="email" placeholder="Ingresa Email">

                                    </div>
                                    <div class="form-group">
                                        <label>Telefono</label>
                                        <input class="form-control" type="password" placeholder="Ingresa Contraseña" >
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Celular</label>
                                        <input class="form-control" placeholder="Ingresa tu Telefono">
                                    </div>
                                    <div class="form-group">
                                            <label>Objetivos</label>
                                            <input class="form-control" placeholder="Ingresa tu Telefono">
                                    </div>
                                    <div class="form-group">
                                            <label>Dependencia</label>
                                            <input class="form-control" placeholder="Ingresa tu Telefono">
                                    </div>

                                    <div class="form-group">
                                            <label>Personal a Cargo</label>
                                            <input class="form-control" placeholder="Ingresa tu Telefono">
                                    </div>
                                    <div class="form-group">
                                            <label>Funciones</label>
                                            <input class="form-control" placeholder="Ingresa tu Telefono">
                                    </div>
                                </div>
                                        <button class="btn btn-primary">Registrar</button>
                                    
                                </div>
                            </form>

                    </div>

                </div>
            </div>
        </div>
    </div> 
    </div>
   
</template>
<script>
export default {
    
}
</script>
