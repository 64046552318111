<template>
  <body class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper">
      <SidebarMain class="ab"></SidebarMain>
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <RouterView>
            </RouterView>
          </div>
        </section>
        
      </div>
      
    </div>
  </body>
</template>

<script>
import $ from 'jquery'
import "../../../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../../../node_modules/admin-lte/plugins/select2/css/select2.min.css"

//import ExampleForm from "./components/ExampleForm.vue"
import SidebarMain from "../admin/dashboard/SidebarMain.vue";


export default {
  name: "App",
  components: {
  //  ExampleForm,
    SidebarMain
  },
  methods: {
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2();

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
  },
  mounted() {
    this.initializeSelect2()
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
.ab{
  margin-top: 98px;
}

</style>
