<template>
  <div>
    <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h1>
          Listado Personal Administrativo
          <router-link to="/admin/create-administrativo"
            ><button class="btn btn-success">Nuevo Personal Administrativo</button></router-link
          >
        </h1>
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-default">
          <div class="panel-heading">Gestion y Registro de Personal Administrativo</div>
          <!-- /.panel-heading -->
          <div class="panel-body">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered table-hover"
                id="dataTables-example"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Apellidos</th>
                    <th>Cargo</th>
                    <th>Email</th>
                    <th>Telefono</th>
                    <th>Celular</th>
                    <th>Objetivo</th>
                    <th>Dependencia</th>
                    <th>Personal a Cargo</th>
                    <th>Funciones</th>
                    <th>Foto</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                   <td>1</td>
                   <td>2</td>
                   <td>3</td>
                   <td>4</td>
                   <td>5</td>
                   <td>6</td>
                   <td>7</td>
                   <td>8</td>
                   <td>9</td>
                   <td>10</td>
                   <td>11</td>
                   <td>12</td>
                    <td>
                    </td>
                    <td class="center">
                      <button type="submit" class="btn btn-warning" >Editar</button>
                      <button class="btn btn-danger" >
                      Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.table-responsive -->
          </div>
          <!-- /.panel-body -->
        </div>
        <!-- /.panel -->
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <!-- /.container-fluid -->
  </div>

  </div>
  
</template>