<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-10">

    <!-- Sidebar -->
    <div class="sidebar mb-5">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="" class="img-circle elevation-2" >
        </div>
        <div class="info">
          <a href="#" class="d-block"></a>
        </div>
      </div>

      <!-- SidebarSearch Form -->
      <div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
               <li class="nav-item">
                <router-link to="/admin/index-proyectoEjecucion" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Proyecto en Ejecucion</p>
                </router-link>
              </li>
               <li class="nav-item">
                <router-link to="/admin/index-convocatorias" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Convocatorias</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-direcciones" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Direcciones</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-reconocimientos" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Reconocimientos</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-institutos" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Institutos</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-anuncios" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Anuncios</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-responsables" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Responsables</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-actividades" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Actividades</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-usuario" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Usuario</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-cursos" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Cursos</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-noticias" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Noticias</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-articulo" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Articulos</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-investigacion" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Investigaciones</p>
                </router-link>
              </li>
              
              <li class="nav-item">
                <router-link to="/admin/index-ferias" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Ferias</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-repositorio" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Repositorio</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-politicas" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Politicas</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-estrategias" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Estrategias</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-sociedad" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar Sociedades Cientificas</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/index-proyectosSoce" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestionar  Proyectos Sociedades Cientificas</p>
                </router-link>
              </li>
              <br>
              <br>
              <br>
              <br>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  data() {
    return {
   
    };
  }
}
</script>

<style>

</style>