<template>




    <!-- Navbar Start -->
    <div class="container-fluid position-relative nav-bar p-0">
        <div class="container-lg position-relative p-0 px-lg-3" style="z-index: 9;">
            <nav class="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5 fixed-top">
                <router-link to="/" class="navbar-brand">
                   <img class="img-thumbnail"  style="width: 100px;" src="../../public/img/logo_dicYt.jpg" alt="logo_dicYt">
                </router-link>
                <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                    <div class="navbar-nav ml-auto py-0">
                        <router-link to="/" class="nav-item nav-link active">Inicio</router-link>
                        <router-link to="/quienes-somos" class="nav-item nav-link">Quienes Somos?</router-link>
                        <router-link to="/sociedades-cientificas" class="nav-item nav-link">Sociedades Cientificas</router-link>
                        <router-link to="/proyectos" class="nav-item nav-link">Proyectos</router-link>
                        <router-link to="/investigacion-index" class="nav-item nav-link">Investigacion</router-link>
                        <router-link to="/ferias" class="nav-item nav-link">Ferias de Ciencias</router-link>
                        <router-link to="/observatorio" class="nav-item nav-link">Observatorio</router-link>
                        <a href="#footer" class="nav-item nav-link">Contactos</a>
                        <!-- <a href="#" class="nav-item nav-link">Ferias</a>
                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Revistas</a>
                            <div class="dropdown-menu border-0 rounded-0 m-0">
                                <a href="#" class="dropdown-item">Blog Grid</a>
                                <a href="#" class="dropdown-item">Blog Detail</a>
                                <a href="#" class="dropdown-item">Destination</a>
                                <a href="#" class="dropdown-item">Travel Guides</a>
                                <a href="#" class="dropdown-item">Testimonial</a> 
                            </div>
                        </div> -->
                       
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Navbar End -->
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>