<template>
    <div>
      <!-- Header Start -->
   <div class="container-fluid page-header">
    <div class="container mt-5">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-4 text-white text-uppercase">DICyT</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><router-link to='/' class="text-white">Inicio</router-link></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">DICyT</p>
            </div>
        </div>
      </div>
   </div>
   <!-- Header End -->
         <!--Information start-->
   <div class="container">
        <hr>
        <div class="row">
            <div class="col-sm-4" style="display: flex; align-items: center;">
                <!-- Column Info 1 -->
                <img class="img-thumbnail" src="../../public/img/logo_dicYt.jpg" alt="" style="height: 300px; width: 300px;">
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center text-uppercase">Dirección de investigación científica y tecnológica de la uto</h2>
                <h5>¿Que hacemos?</h5>
                <p class="text-wrap" style="text-align: justify;" >
                    La Universidad Técnica de Oruro basa su accionar en tres áreas: la formación superior de recursos humanos, la investigación y desarrollo tecnológico, y la vinculación de la Universidad con la sociedad civil.
                    Desde la ex Dirección de Postgrado e Investigación Científica y las unidades de investigación especializadas de cada Facultad de la UTO, se fueron ejecutando proyectos de investigación de nivel de pregrado y postgrado a fin de aportar al desarrollo de nuestra región y el país en general; en los últimos años con recursos del IDH también se han generado proyectos de investigación. En la presente gestión se cuenta con alrededor de 180 proyectos de investigación a través de la Dirección de Investigación Científica y Tecnológica, así como también de la Dirección de Vinculación Universitaria, por tanto, existe una nutrida actividad investigativa dentro de la Universidad, muchas de ellas con interesantes resultados para nuestra región.
                    Por tanto, es necesario que la UTO cuente con una instancia de difusión de toda la actividad científica y tecnológica ante la sociedad e instituciones de Oruro.
                </p>
                <hr>
            </div>
        </div>
        <div class="row" id="myv">
            <div class="col-sm-4" style="display: flex; align-items: center;">
                <!-- Column Info 1 -->
                <img class="img-thumbnail"  src="../../public/img/Mision y Vision.png" alt="" style="height: 300px; width: 300px;">
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center ">Misión y Visión</h2>
                <h5>
                    Misión
                </h5>
                <p class="text-wrap" style="text-align: justify;">
                    Fomentar y realizar investigación, desarrollo e innovación, en la comunidad universitaria de la UTO (docentes y estudiantes), para el crecimiento de la ciencia y conocimiento, mejorando las capacidades profesionales de sus integrantes y para el beneficio del Sistema de la Universidad Boliviana, de los bolivianos y de las organizaciones públicas y privadas de Bolivia, principalmente en las áreas de mayor competencia profesional de la UTO y en las áreas de mayor relevancia.
                </p>
                <hr>
                <h5>
                    Visión
                </h5>
                <p class="text-wrap" style="text-align: justify;">
                    La UTO fomenta y realiza investigación, desarrollo e innovación de calidad publicada en revistas indexadas, que conduce a la generación de patentes, desarrollo productivo y social y a la formación de profesionales con competencias investigativas y conocimiento científico para el desarrollo económico y social de Oruro y Bolivia.
                </p>
                <hr>
            </div>
        </div>
    </div>
   <!--Information end-->
    <!-- Team Start -->
    <div class="container-fluid py-5" id="equip">
        <div class="container pt-5 pb-3">
            <div class="text-center mb-3 pb-3">
                <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">Equipo</h6>
                <h1>Personal Administrativo</h1>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/Ing. Loayza.jpeg" alt="">
                            <div class="team-social">
                              
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/68301437"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:juanloayzafcan@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info6"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info6" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Ing.Juan Loayza Aguilar</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body justify">
                                                        Planificar, dirigir, coordinar, controlar y promover las actividades de investigación científica y tecnológica e innovación en el marco de la Planificación institucional de corto y mediano plazo y el cumplimiento de la normativa vigente.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Vicerrectorado
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Coordinador área ingeniería y tecnología, coordinador del observatorio regional de Oruro, coordinador de innovación tecnológica y humanística, profesional especializado, responsable administrativo, comunicador social y secretaria de dirección
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Formular, coordinar, ejecutar y supervisar el programa de operaciones anual de la dirección, de acuerdo al plan estratégico institucional de la Universidad Técnica de Oruro</li>
                                                            <li>Elaborar e implementar el plan de investigación, ciencia, tecnología e innovación</li>
                                                            <li>Gestionar el fortalecimiento de los recursos humanos para el desarrollo de la investigación</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4 pb-5 pt-5">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Ing.Juan Loayza Aguilar</h5>
                            <br>
                            <p class="m-0">DIRECTOR DE LA DICYT</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/ING-SALAS.png" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/77158796"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:milton.salas@uto.edu.bo"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info5"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info5" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Dr. Ing. Milton Salas Cazon</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Promover las actividades de investigación, ciencia y tecnología desarrolladas por docentes y estudiantes de la Universidad Técnica de Oruro
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Director de investigación ciencia y tecnología 
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Coadyuvar en la formulación, ejecución y supervisión del programa de operaciones anual de la dirección</li>
                                                            <li>Promover y orientar a docentes y estudiantes de las unidades facultativas en la ejecución y participación en programas de investigación y desarrollo (I+D)</li>
                                                            <li>Apoyar técnicamente el comité cientifíco de la Universidad Técnica de Oruro y órganos similares para el cumplimiento regular y oportuno de sus funciones, de acuerdo a requerimientos e instituciones del Director</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4 pb-5 pt-5">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Dr. Ing. Milton Salas Cazon</h5>
                            <p class="m-0">COORDINADOR DICyT</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/ing.-willy-choque.jpg" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/67201310"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:w.choque.m@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info4"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info4" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">MSc. Ing. Agr. Willy Choque Marca</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Gestionar espacios concurrentes para las actividades científicas y tecnológicas de la Universidad Técnica de Oruro con la finalidad de lograr sostenibilidad e impacto en el desarrollo rergional
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Director de investigación ciencia y tecnología 
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Coadyuvar en la formulación, ejecución y supervisión del programa de operaciones anual de la dirección</li>
                                                            <li>Promover alianzas institucionales para financiar proyecto y proyectos de investigación e información científicas y tecnológicas, para la base de datos del observatorio</li>
                                                            <li>Apoyar técnicamente al comité científico de la DICyT</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">MSc. Ing. Agr. Willy Choque Marca</h5>
                            <p class="m-0">COORDINADOR CENTRO INTERDISCIPLINARIO Y ESTUDIOS REGIONALES</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/Lic. sefora-condarco.jpg" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/67302385"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:stcondarco@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info3"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info3" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Lic. Sefora Telma Condarco Perez</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Administrar adecuadamente los recursos económicos asignados a los diferentes proyectos de investigación, científica y tecnológica de la Universidad Técnica de Oruro, en aplicación a normativa vigente
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Director de investigación ciencia y tecnología
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Elaborar y coadyuvar en el cumplimiento del programa operativo anual de la dirección</li>
                                                            <li>Verificar los comprobantes de contabilidad, relacionados al movimiento de recursos y gastos de los proyectos de investigación, según normativa vigente</li>
                                                            <li>Efectuar apertura de fondo de trabajo y cuentas auxiliares que requiera la DICyT</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4 pb-5 pt-2">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Lic. Sefora Telma Condarco Perez</h5>
                            <p class="m-0">ASISTENTE CONTABLE DE LA DICYT</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/Ing-Gustavo.jpeg" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/68324008"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:gustomapa@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info2"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info2" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Ing. Gustavo Casto Mamani Parrilla</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Desarrollar y fortalecer capacidades de gestión solidaria en el ámbito de la educación ambiental y la gestión de cuencas hidrográficas. El coordinador tiene la responsabilidad de liderar y coordinar las actividades relacionadas con la cuenca pedagógica.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Dirección de investigación, ciencia y tecnología
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Coordinar y liderar las actividades relacionadas con la cuenca pedagógica, asegurando la implementación efectiva de los programas y proyectos educativos</li>
                                                            <li>Establecer y mantener alianzas estratégicas con instituciones educativas, organizaciones comunitarias, entidades gubernamentales y otros actores relevantes en el ámbito de la gestión de cuencas hidrográficas</li>
                                                            <li>Promover la participación activa de los diferentes actores involucrados en la cuenca pedagógica, fomentando la colaboración y el trabajo en red.</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Ing. Gustavo Casto Mamani Parrilla</h5>
                            <p class="m-0">COORDINADOR CUENCA PEDAGOGICA GUARDAÑA</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/lic-walter-ignacio.jpg" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/71887762"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:wiv2196@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info1"><i class="fas fa-info-circle"></i></a>
                                <div class="modal fade" id="info1" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Lic. Walter Ignacio Vasquez</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Promover las actividades de comunicación de la Dirección de investigación, ciencia y tecnología de la U.T.O
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Dirección de investigación, ciencia y tecnología
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Coadyuvar en el cumplimiento del programa operativo anual de la dirección</li>
                                                            <li>Implementar estrategias comunicacionales de la DICyT aplicando las tecnologías de la información y comunicación</li>
                                                            <li>Desempeñarse como reportero, diagramador, diseñador, fotógrafo y responsable de información y redacción que genera la DICyT</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4">
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Lic. Walter Ignacio Vasquez</h5>
                            <p class="m-0" >RESPONSABLE DEL ÁREA DE COMUNICACIÓN DICYT</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 pb-2">
                    <div class="team-item bg-white mb-4">
                        <div class="team-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src="../../public/img/Lic. Lito.jpeg" alt="">
                            <div class="team-social">
                                <a class="btn btn-outline-primary btn-square" href="tel:+5915280590"><i class="fa fa-phone-alt"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="https://wa.me/71883855"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="mailto:lito.arias.li@gmail.com"><i class="fa fa-envelope"></i></a>
                                <a class="btn btn-outline-primary btn-square" href="" data-toggle="modal" data-target="#info" ><i class="fas fa-info-circle"></i></a>
                                <!-- Modal -->
                                <div class="modal fade" id="info" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="row bg-primary ">
                                                <div class="col" style="width: 320px;">
                                                    <h5 class="modal-title text-white">Lic. Edwin Arias Limachi</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="accordion" id="accordion">
                                                <div class="card">
                                                  <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Objetivo
                                                      </button>
                                                    </h2>
                                                  </div>
                                                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        Brindar apoyo logístico a la dirección y personal técnico en las diversas actividades, facilitando el acceso a los materiales, personas e instancias que permitan la obtención de resultados ágiles y óptimos para la toma de decisiones.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                                          Dependencia
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Director de investigación ciencia y tecnología 
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                                          Personal a cargo
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                        Ninguno
                                                      </div>
                                                    </div>
                                                </div>
                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                      <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                                          Principales funciones
                                                        </button>
                                                      </h2>
                                                    </div>
                                                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                      <div class="card-body">
                                                         <ul>
                                                            <li>Coadyuvar en el cumplimiento y ejecución del programa operativo anual de la dirección</li>
                                                            <li>Efectuar la atención y recepción de documentos al público con cordialidad</li>
                                                            <li>Recibir y despachar la correspondencia de la dirección de investigación, ciencia y tecnología</li>
                                                         </ul>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer" id="holaWil">
                                          <button type="button" id="holaWil" class="btn btn-primary" data-dismiss="modal" >Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-4 pb-5 pt-5" >
                            <h5 class="text-truncate" style="text-wrap:wrap ;">Lic. Edwin Arias Limachi</h5>
                            <p class="m-0">SECRETARIO DE LA DICYT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team End -->
    <!--Organigrama start-->
    <div class="container-fluid py-5">
        <div class="text-center mb-3 pb-3">
            <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">DYCyT</h6>
            <h1>Organigrama</h1>
        </div>
        <div class="container" >
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-11 col-lg-6" style="min-height: 500px;">
                    <div class="position-relative h-100">
                        <img class="position-absolute" id="idOrga" src="../../public/img/OrganigramaDICyT.png">
                        <button class="boton-orga"><img class="orgaIngLoayza" src="../../public/img/Ing. Loayza.jpeg" alt=""></button>
                        <button class="boton-orga"><img class="orgaIngWilly" src="../../public/img/ing.-willy-choque.jpg" alt=""></button>
                        <button class="boton-orga"><img class="orgaIngSalas" src="../../public/img/ING-SALAS.png" alt=""></button>
                        <button class="boton-orga"><img class="orgaIngGus" src="../../public/img/Ing-Gustavo.jpeg" alt=""></button>
                        <button class="boton-orga"><img class="orgaLicLito" src="../../public/img/Lic. Lito.jpeg" alt=""></button>
                        <button class="boton-orga"><img class="orgaLicWalter" src="../../public/img/lic-walter-ignacio.jpg" alt=""></button>
                        <button class="boton-orga"><img class="orgaLicSefora" src="../../public/img/Lic. sefora-condarco.jpg" alt=""></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Organigrama End -->


    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="fa fa-angle-double-up"></i></a>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>