<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Direcciones
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Direcciones
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Direccion" v-model=direccion.nombre>
                                    </div>
                                    <div class="form-descripcion">
                                        <label>Descripcion</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Descripcion" v-model=direccion.descripcion>

                                    </div>


                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Contacto</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Contacto" v-model=direccion.documento>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>imagen</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=direccion.imagen>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveDireccion()">Registrar</button>
                                        <button class="btn btn-danger" @click="backDireccion()">Cancelar</button>
                            </div>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            direccion:{
                nombre:'',
                descripcion:'',
                documento:"",
                imagen:''
            }
        }
    },
    methods:{
        async saveDireccion(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/direcciones/crear',this.direccion).
            then(res =>console.log(res))
            this.$router.push('/admin/index-direcciones');
        },
        backDireccion(){
        this.$router.push('/admin/index-direcciones');
    }
    }
}
</script>
