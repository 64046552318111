<template>
    <div>
            <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Ferias y Ciencias</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                    <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Ferias y Ciencias</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

        <!-- Booking Start -->
        <div class="container-fluid booking mt-5 pb-5">
            <div class="container pb-5">
                <div class="bg-light shadow" style="padding: 30px;">
                    <div class="row align-items-center" style="min-height: 60px;">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="mb-3 mb-md-0">
                                        <select class="custom-select px-4" ref="nombre" style="height: 47px;">
                                            <option selected>FICYT</option>
                                            <option v-for="feria in feriasNombre" :key="feria.id">
                                                <div>
                                                    {{ feria }}
                                                </div>

                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3 mb-md-0">
                                        <select class="custom-select px-4" ref="area" style="height: 47px;">
                                            <option selected>Area</option>
                                            <option v-for="feria in feriasArea" :key="feria.div">
                                                <div>
                                                    {{ feria }}
                                                </div>

                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary btn-block" type="button" style="height: 47px; margin-top: -2px;" @click="searchFeria()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Booking End -->

<!-- Convocatorias start -->
<div class="container ">
    <h2 class="text-secondary text-center mb-5 pt-3">MOSTRAR RESULTADOS DE BUSQUEDA</h2>
    <div class="container ">
        <div class="row" v-for="feria in ferias" :key="feria.id">
            <div class="col-sm-4">
                <GoogleDriveViewer :driveUrl="feria.imagen" />
                <GoogleDriveViewer :driveUrl="feria.documento" />
            </div>
            <div class="col-sm-8 ps-5">
                <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ feria.nombre }}</strong></a>
                <hr>
            </div>
        </div>
    </div>
</div>
<!--courses end -->

    </div>
</template>

<script>
import GoogleDriveViewer from '@/components/helpers/GoogleDriveViewer'; // Ruta al componente GoogleDriveViewer.vue
import axios from 'axios'
    export default {
        components :{
            GoogleDriveViewer
        },
        data(){
            return {
                ferias:null,
                feriasNombre:null,
                feriasArea:null
            }
        },
        mounted(){
            this.onLoadFerias()
        },
        methods:{
            async onLoadFerias(){
                const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/ferias/listado')
                const feriasNombre =data.map(e=>e.nombre)
                const feriasArea =data.map(e=>e.area)
                const nombreFeria = new Set(feriasNombre)
                const nombreArea = new Set(feriasArea)
                this.feriasNombre=nombreFeria
                this.feriasArea=nombreArea
            },
            async searchFeria(){
                const nombre =this.$refs.nombre.value
                const area =this.$refs.area.value
                const {data} = await axios.post(process.env.VUE_APP_API_BASE_URL + '/ferias/buscar',{
                    nombre,
                    area
                })
                //mostrar 5 primeros registros
                const firstFive=data.slice(0,5)
                this.ferias=firstFive

            }

        }
    }
</script>

<style lang="scss" scoped>

</style>