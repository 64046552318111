<template>
      <div>
    <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div>
        <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Editar Investigaciones y Publicaciones
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Investigaciones y Publicaciones
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre Investigador</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Investigador" v-model="investigadoresEdit.nombre_investigador">
                                    </div>
                                    <div class="form-group">
                                        <label>Foto Investigador</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Foto " v-model="investigadoresEdit.foto_investigador">

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Carrera</label>
                                        <input class="form-control" placeholder="Ingresa Carrera" v-model="investigadoresEdit.carrera">
                                    </div>

                                    <div class="form-group">
                                        <label>Facultad</label>
                                        <input class="form-control" placeholder="Ingresa Facultad" v-model="investigadoresEdit.facultad">
                                    </div>
                                    </div>
                                </div>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditConvocatoria">Guardar Cambios</button>
      </div>
    </div>
  </div>
</div>
    </div>




  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h1>
          Listado de Investigaciones y Publicaciones
          <router-link to="/admin/create-investigacion"
            ><button class="btn btn-success">Nueva Investigacion </button></router-link
          >
        </h1>
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-default">
          <div class="panel-heading">Gestion y Registro de Investigaciones y Publicaciones</div>
          <!-- /.panel-heading -->
          <div class="panel-body">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered table-hover"
                id="dataTables-example"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre Investigador</th>
                    <th>Foto Investigador</th>
                    <th>Carrera</th>
                    <th>Facultad</th>
                    <th>Articulos Publicados</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody v-for="inv in investigadores" :key="inv.id">
                  <tr>
                   <td>{{inv.id}}</td>
                   <td>{{inv.nombre_investigador}}</td>
                   <td>{{ inv.foto_investigador}}</td>
                   <td>{{ inv.carrera }}</td>
                   <td>{{ inv.facultad }}</td>
                   <td>{{ inv.articulos_publicados }}</td>
                    <td class="center">
                      <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editInvestigadores(inv.id)'>
                        Editar

                      </button>
                      <button class="btn btn-danger" @click="deleteInvestigadores(inv.id)">
                      Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.table-responsive -->
          </div>
          <!-- /.panel-body -->
        </div>
        <!-- /.panel -->
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <!-- /.container-fluid -->
  </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            investigadores:null,
            investigadoresEdit:{}
        }
    },
    mounted(){
        this.onLoadInvestigadores()
    },
    methods:{
        async onLoadInvestigadores(){
           const {data}=await axios.get(process.env.VUE_APP_API_BASE_URL + '/trabajos_investigacion/listado')
           this.investigadores=data

        },
        async deleteInvestigadores(id){
       await axios.delete(process.env.VUE_APP_API_BASE_URL + '/trabajos_investigacion/eliminar',{
        data:{id}
      })
        this.onLoadInvestigadores()
    },
    async editInvestigadores(id){
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/trabajos_investigacion/mostrar/'+id).then(res =>{
                const {data}=res
                this.investigadoresEdit=data[0]
            })
    },
    async saveEditConvocatoria(){
      const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/trabajos_investigacion/actualizar',this.investigadoresEdit)
      console.log(data);
      this.onLoadInvestigadores()
    }
    },


}
</script>

