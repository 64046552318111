<template>
    <div>
      <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div>
          <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
          <h1>
            Editar Actividades
          </h1>
        </div>
              <div class="col-lg-12">
                  <div class="panel panel-default">
                      <div class="panel-heading">
                          Formulario de Registro de Actividades
                      </div>
                      <div class="panel-body">
                              <form role="form">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Nombre</label>
                                          <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model="actividadesEdit.nombre">
                                      </div>
                                      <div class="form-group">
                                          <label>Imagen 1</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="actividadesEdit.imagen1">

                                      </div>
                                      <div class="form-group">
                                          <label>Imagen 2</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="actividadesEdit.imagen2">

                                      </div>
                                      <div class="form-group">
                                          <label>Imagen 3</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="actividadesEdit.imagen3">

                                      </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Descripcion</label>
                                          <textarea class="form-control" placeholder="Ingresa la Descripcion" v-model="actividadesEdit.descripcion"> </textarea>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Tipo</label>
                                          <select id="options" class="form-control" v-model="actividadesEdit.tipo">
                                        <option value="guardana">Guardaña</option>
                                        <option value="sulloma">Sulloma</option>
                                        <option value="fluvial">Agua Fluvial</option>
                                        <option value="residual">Agua Residual</option>
                                        </select>
                                      </div>
                                      </div>
                                  </div>
                                  </div>
                                  </form>

                      </div>

                  </div>
              </div>
          </div>
      </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditActividades">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>
      </div>




    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1>
            Listado Actividades
            <router-link to="/admin/create-actividades"
              ><button class="btn btn-success">Nueva Actividad </button></router-link
            >
          </h1>
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="panel panel-default">
            <div class="panel-heading">Gestion y Registro de Actividades</div>
            <!-- /.panel-heading -->
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  class="table table-striped table-bordered table-hover"
                  id="dataTables-example"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Descripcion</th>
                      <th>Imagen 1</th>
                      <th>Imagen 2</th>
                      <th>Imagen 3</th>
                      <th>Tipo</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody v-for="conv in actividades" :key="conv.id">
                    <tr>
                     <td>{{conv.id}}</td>
                     <td>{{conv.nombre}}</td>
                     <td>{{ conv.descripcion }}</td>
                     <td>{{ conv.imagen1 }}</td>
                     <td>{{ conv.imagen2 }}</td>
                     <td>{{ conv.imagen3 }}</td>
                     <td>{{ conv.tipo }}</td>
                      <td class="center">
                        <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editActividades(conv.id)'>
                          Editar


                        </button>
                        <button class="btn btn-danger" @click="deleteActividades(conv.id)">
                        Eliminar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.table-responsive -->
            </div>
            <!-- /.panel-body -->
          </div>
          <!-- /.panel -->
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <!-- /.container-fluid -->
    </div>

    </div>

  </template>
  <script>
  import axios from 'axios'
  export default {
      data(){
          return{
              actividades:null,
              actividadesEdit:{}
          }
      },
      mounted(){
          this.onLoadActividades()
      },
      methods:{
          async onLoadActividades(){
             await axios.get(process.env.VUE_APP_API_BASE_URL + '/actividades/listado').
              then(res =>{
                  const {data}=res
                  this.actividades=data
              })
          },
          async deleteActividades(id){
         await axios.delete(process.env.VUE_APP_API_BASE_URL + '/actividades/eliminar',{
          data:{id}
        })
          this.onLoadActividades()
      },
      async editActividades(id){
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/actividades/mostrar/'+id).then(res =>{
                  const {data}=res
                  this.actividadesEdit=data[0]
              })
      },
      async saveEditActividades(){
        const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/actividades/actualizar',this.actividadesEdit)
        console.log(data);
        this.onLoadActividades()
      }
      },


  }
  </script>

