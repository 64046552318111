<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Investigaciones y Publicaciones
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Investigaciones
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre Investigador</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Investigador" v-model=investigacion.nombre_investigador>
                                    </div>
                                    <div class="form-group">
                                        <label>Foto Investigador</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=investigacion.foto_investigador>

                                    </div>

                                    <div class="form-group">
                                        <label>Carrera</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Carrera" v-model=investigacion.carrera>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Facultad</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Facultad" v-model=investigacion.facultad>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveInvestigacion()">Registrar</button>
                                        <button class="btn btn-danger" @click="backInvestigacion()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            investigacion:{
                nombre_investigador:'',
                foto_investigador:'',
                carrera:"",
                facultad:""
            }
        }
    },
    methods:{
        async saveInvestigacion(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/trabajos_investigacion/crear',this.investigacion).
            then(res =>console.log(res))
            this.$router.push('/admin/index-investigacion');
        },
        backInvestigacion(){
        this.$router.push('/admin/index-investigacion');
    }
    }
}
</script>
