import { createRouter, createWebHashHistory } from 'vue-router'
import IndexComp from '@/components/IndexComp'
import QuienesSomos from '@/components/QuienesSomos'
import FeriasCiencias from '@/components/FeriasCiencias'
import IndexProyecto from '@/components/proyectos/IndexProyecto'
import ProGuarana from '@/components/proyectos/ProGuarana'
import CuencaSulloma from '@/components/proyectos/CuencaSulloma'
import AguaResidual from '@/components/proyectos/AguaResidual'
import AguaFluvial from '@/components/proyectos/AguaFluvial'
import DatosMeteorologicos from '@/components/proyectos/DatosMeteorologicos'
import ProyectosConcluidos from '@/components/proyectos/ProyectosConcluidos'
import IndexInvestigacion from '@/components/investigacion/IndexInvestigacion'
import PublicaionesInv from '@/components/investigacion/PublicaionesInv'
import InvestigacionesPublicaciones from '@/components/investigacion/InvestigacionesPublicaciones'
import TesisGrado from '@/components/investigacion/TesisGrado'
import TesisPostgrado from '@/components/investigacion/TesisPostgrado'
import ReconocimientosPremios from '@/components/investigacion/PremiosDistinciones'
import DireccionesInv from '@/components/investigacion/DireccionesInv'
import InstitutosInv from '@/components/investigacion/InstitutosInv'
import ObservatorioD from '@/components/ObservatorioD'
import FooterBar from '@/components/FooterBar'
//DASHBOARD
import IndexAdmin from '../components/admin/IndexAdmin.vue'
//PERSONAL ADMINISTRATIVO
import IndexAdministrativos from '@/components/admin/administrativo/IndexAdministrativos'
import CreateAdministrativo from '@/components/admin/administrativo/CreateAdministrativo'

//PROYECTOS 
import IndexProyectos from '@/components/admin/proyectos/IndexProyectos'
import CreateProyectos from '@/components/admin/proyectos/CreateProyectos'
//CONVOCATORIAS
import IndexConvocatorias from '@/components/admin/convocatorias/IndexConvocatorias'
import CreateConvocatorias from '@/components/admin/convocatorias/CreateConvocatorias'

//INVESTIGACIONES

import IndexInvestigaciones from '@/components/admin/investigacion/IndexInvestigacion'
import CreateInvestigaciones from '@/components/admin/investigacion/CreateInvestigacion'

//ARTICULOS

import IndexArticulos from '@/components/admin/articulos/IndexArticulos.vue'
import CreateArticulos from '@/components/admin/articulos/CreateArticulos.vue'
//CURSOS
import IndexCursos from '@/components/admin/cursos/IndexCursos'
import CreateCursos from '@/components/admin/cursos/CreateCursos'

// NOTICIAS

import IndexNoticias from '@/components/admin/noticias/IndexNoticias'
import CreateNoticias from '@/components/admin/noticias/CreateNoticias'

//FERIAS

import IndexFerias from '@/components/admin/ferias/IndexFerias'
import CreateFerias from '@/components/admin/ferias/CreateFerias'

import LoginForm from '@/components/login/LoginForm'

import IndexUsuario from '@/components/admin/login/IndexLogin'
import CreateUsuario from '@/components/admin/login/CreateUsuario'


import IndexResponsable from '@/components/admin/responsables/IndexResponsable'
import CreateResponsable from '@/components/admin/responsables/CreateResponsable'

import IndexActividad from '@/components/admin/actividades/IndexActiviadades'
import CreateActividad from '@/components/admin/actividades/CreateActividades'

import IndexAnuncio from '@/components/admin/anuncios/IndexAnuncios'
import CreateAnuncio from '@/components/admin/anuncios/CreateAnuncios'

import IndexInstituto from '@/components/admin/institutos/IndexInstitutos'
import CreateInstituto from '@/components/admin/institutos/CreateInstituto'

import IndexDirecciones from '@/components/admin/direcciones/IndexDirecciones'
import CreateDirecciones from '@/components/admin/direcciones/CreateDirecciones'

import IndexReconocimientos from '@/components/admin/reconocimientos/IndexReconocimientos'
import CreateReconocimientos from '@/components/admin/reconocimientos/CreateReconocimientos'

import IndexProyectoEjecucion from '@/components/admin/proyectoEjecucion/IndexProyectoEjecucion'

import IndexRepositorio from '@/components/admin/observatorio/repositorio/IndexRepositorio'
import CreateRepositorio from '@/components/admin/observatorio/repositorio/CreateRepositorio'

import IndexPoliticas from '@/components/admin/observatorio/politicas/IndexPoliticas'
import CreatePoliticas from '@/components/admin/observatorio/politicas/CreatePoliticas'

import IndexEstrategias from '@/components/admin/observatorio/estrategias/IndexEstrategias'
import CreateEstrategias from '@/components/admin/observatorio/estrategias/CreateEstrategias'

import IndexSociedadCientifica from '@/components/admin/sociedadCientifica/IndexSociedad'
import CreateSociedadCientifica from '@/components/admin/sociedadCientifica/CreateSociedad'
import SociedadesCientificas from '@/components/SociedadesCientificas'

import CreateProyectosSoce from '@/components/admin/ProyectosSoce/CreateProyectoSoce'
import IndexProyectosSoce from '@/components/admin/ProyectosSoce/IndexProyectoSoce'
const routes = [
  {
    path:'/login',
    name:'login',
    component: LoginForm
  },
  {
    path:'/admin',
    name:'admin',
    component: IndexAdmin,
    meta: { requiresAuth: true },
    children:[
      {
        path:'index-proyectosSoce',
        component :IndexProyectosSoce,
      },
      {
        path:'create-proyectoSoce',
        component :CreateProyectosSoce,
      },
      {
        path:'index-proyectoEjecucion',
        component :IndexProyectoEjecucion,
      },
      {
        path:'index-usuario',
        component :IndexUsuario,
      },
      {
        path:'create-usuario',
        component :CreateUsuario,
      },
      {
        path:'index-institutos',
        component :IndexInstituto,
      },
      {
        path:'create-institutos',
        component :CreateInstituto,
      },
      {
        path:'index-reconocimientos',
        component :IndexReconocimientos,
      },
      {
        path:'create-reconocimientos',
        component :CreateReconocimientos,
      },
      {
        path:'index-direcciones',
        component :IndexDirecciones,
      },
      {
        path:'create-direcciones',
        component :CreateDirecciones,
      },
      {
        path:'index-convocatorias',
        component :IndexConvocatorias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-convocatorias',
        component :CreateConvocatorias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-usuario',
        component :CreateUsuario,
      },
      {
        path:'index-responsables',
        component :IndexResponsable,
        meta: { requiresAuth: true }
      },
      {
        path:'create-responsables',
        component :CreateResponsable,
        meta: { requiresAuth: true }
      },
      {
        path:'index-anuncios',
        component :IndexAnuncio,
        meta: { requiresAuth: true }
      },
      {
        path:'create-anuncios',
        component :CreateAnuncio,
        meta: { requiresAuth: true }
      },
      {
        path:'index-actividades',
        component :IndexActividad,
        meta: { requiresAuth: true }
      },
      {
        path:'create-actividades',
        component :CreateActividad,
        meta: { requiresAuth: true }
      },
      {
        path:'index-cursos',
        component :IndexCursos,
        meta: { requiresAuth: true }
      },
      {
        path:'create-cursos',
        component :CreateCursos,
        meta: { requiresAuth: true }
      },
      {
        path:'index-ferias',
        component :IndexFerias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-ferias',
        component :CreateFerias,
        meta: { requiresAuth: true }
      },
      {
        path:'index-noticias',
        component :IndexNoticias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-noticias',
        component :CreateNoticias,
        meta: { requiresAuth: true }
      },
      {
        path:'index-administrativo',
        component :IndexAdministrativos,
        meta: { requiresAuth: true }
      },
      {
        path:'create-administrativo',
        component :CreateAdministrativo,
        meta: { requiresAuth: true }
      },
      {
        path:'index-proyectos',
        component :IndexProyectos,
        meta: { requiresAuth: true }
      },
      {
        path:'create-proyectos',
        component :CreateProyectos,
        meta: { requiresAuth: true }
      },
      {
        path:'index-ferias',
        component :IndexFerias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-ferias',
        component :CreateFerias,
        meta: { requiresAuth: true }
      },
      {
        path:'index-investigacion',
        component :IndexInvestigaciones
      },
      {
        path:'create-investigacion',
        component :CreateInvestigaciones,
        meta: { requiresAuth: true }
      },
      {
        path:'index-articulo',
        component :IndexArticulos,
        meta: { requiresAuth: true }
      },
      {
        path:'create-articulo',
        component :CreateArticulos,
        meta: { requiresAuth: true }
      },
      {
        path:'index-repositorio',
        component :IndexRepositorio,
        meta: { requiresAuth: true }
      },
      {
        path:'create-repositorio',
        component :CreateRepositorio,
        meta: { requiresAuth: true }
      },
      {
        path:'index-politicas',
        component :IndexPoliticas,
        meta: { requiresAuth: true }
      },
      {
        path:'create-politicas',
        component :CreatePoliticas,
        meta: { requiresAuth: true }
      },
      {
        path:'index-estrategias',
        component :IndexEstrategias,
        meta: { requiresAuth: true }
      },
      {
        path:'create-estrategias',
        component :CreateEstrategias,
        meta: { requiresAuth: true }
      },
      {
        path:'index-sociedad',
        component :IndexSociedadCientifica,
        meta: { requiresAuth: true }
      },
      {
        path:'create-sociedad',
        component :CreateSociedadCientifica,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: IndexComp
  },
  {
    path: '/contactos',
    name: 'contactos',
    component: FooterBar
  },
  {
    path: '/quienes-somos',
    name: 'quienes-somos',
    component: QuienesSomos
  },
  {
    path: '/ferias',
    name: 'ferias',
    component: FeriasCiencias
  },
  {
    path: '/observatorio',
    name: 'observaotrio',
    component: ObservatorioD
  },
  {
    path:'/proyectos',
    name:'proyectos',
    component: IndexProyecto
  },{
    path:'/proyectos/guardana',
    name:'proyectos-guarana',
    component: ProGuarana
  },{
    path:'/proyectos/sulloma',
    name:'proyectos-sulloma',
    component: CuencaSulloma
  },
  {
    path:'/proyectos/agua-residual',
    name:'proyectos-agua',
    component: AguaResidual
  },
  {
    path:'/proyectos/agua-fluvial',
    name:'proyectos-fluvial',
    component: AguaFluvial
  },
  {
    path:'/proyectos/datos-meteorologicos',
    name:'proyectos-meteorologia',
    component: DatosMeteorologicos
  },
  {
    path:'/proyectos/concluidos',
    name:'proyectos-concluidos',
    component: ProyectosConcluidos
  },
  {
    path:'/investigacion-index',
    name:'investigacion-index',
    component: IndexInvestigacion
  },
  {
    path:'/investigacion/Publicaciones',
    name:'investigacion',
    component: PublicaionesInv
  },
  {
    path:'/investigacion/Investigacion',
    name:'investigacion-investigacion-publicaciones',
    component: InvestigacionesPublicaciones
  },
  {
    path:'/investigacion/Tesis de grado',
    name:'investigacion-tesis-grado',
    component: TesisGrado
  },
  {
    path:'/investigacion/Tesis de Postgrado',
    name:'investigacion-tesis-postgrado',
    component: TesisPostgrado
  },
  {
    path:'/investigacion/Direcciones',
    name:'investigacion-direcciones',
    component: DireccionesInv
  },
  {
    path:'/investigacion/Institutos',
    name:'investigacion-institutos',
    component: InstitutosInv
  },

  {
    path:'/investigacion/Reconocimientos',
    name:'investigacion-institutos',
    component: ReconocimientosPremios
  },
  {
    path:'/sociedades-cientificas',
    name:'sociedades-cientificas',
    component: SociedadesCientificas
  }









]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
