<template>
    <div>
      <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div>
          <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
          <h1>
            Editar Estrategias
          </h1>
        </div>
              <div class="col-lg-12">
                  <div class="panel panel-default">
                      <div class="panel-heading">
                          Formulario de Registro de Estrategias
                      </div>
                      <div class="panel-body">
                              <form role="form">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                          <label>Nombre</label>
                                          <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model="accionesEdit.nombre">
                                      </div>
                                      <div class="form-group">
                                          <label>Nombre Proyecto</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="accionesEdit.proyecto">

                                      </div>
                                      <div class="form-group">
                                          <label>Archivo</label>
                                          <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="accionesEdit.archivo">
                                      </div>
                                      </div>
                                  </div>
                                  </form>

                      </div>

                  </div>
              </div>
          </div>
      </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditRepositorio">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>
      </div>




    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h1>
            Listado Estrategias
            <router-link to="/admin/create-estrategias"
              ><button class="btn btn-success">Nuevas Estrategias</button></router-link
            >
          </h1>
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="panel panel-default">
            <div class="panel-heading">Gestion y Registro de Estrategias</div>
            <!-- /.panel-heading -->
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  class="table table-striped table-bordered table-hover"
                  id="dataTables-example"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Proyecto</th>
                      <th>Archivo</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody v-for="feria in acciones" :key="feria.id">
                    <tr>
                     <td>{{feria.id}}</td>
                     <td>{{feria.nombre}}</td>
                     <td>{{feria.proyecto}}</td>
                     <td>{{ feria.archivo }}</td>
                      <td class="center">
                        <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editRespositorio(feria.id)'>
                          Editar


                        </button>
                        <button class="btn btn-danger" @click="deleteRepositorio(feria.id)">
                        Eliminar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.table-responsive -->
            </div>
            <!-- /.panel-body -->
          </div>
          <!-- /.panel -->
        </div>
        <!-- /.col-lg-12 -->
      </div>
      <!-- /.row -->
      <!-- /.container-fluid -->
    </div>

    </div>

  </template>
  <script>
  import axios from 'axios'
  export default {
      data(){
          return{
              acciones:null,
              accionesEdit:{}
          }
      },
      mounted(){
          this.onLoadRepositorio()
      },
      methods:{
          async onLoadRepositorio(){
             await axios.get(process.env.VUE_APP_API_BASE_URL + '/acciones/listado').
              then(res =>{
                  const {data}=res
                  this.acciones=data
              })
          },
          async deleteRepositorio(id){
         await axios.delete(process.env.VUE_APP_API_BASE_URL + '/acciones/eliminar',{
          data:{id}
        })
          this.onLoadRepositorio()
      },
      async editRespositorio(id){
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/acciones/mostrar/'+id).then(res =>{
                  const {data}=res
                  this.accionesEdit=data[0]
              })
      },
      async saveEditRepositorio(){
        const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/acciones/actualizar',this.accionesEdit)
        console.log(data);
        this.onLoadRepositorio()
      }
      },


  }
  </script>

