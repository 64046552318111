<template>
    <div>
        <!-- Header Start -->
<div class="container-fluid page-header">
    <br>
      <br>
      <br>
      <br>
    <div class="container">
      <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
          <h3 class="display-4 text-white text-uppercase">Observatorio</h3>
          <div class="d-inline-flex text-white">
            <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
              <p class="m-0 text-uppercase">Observatorio</p>
          </div>
      </div>
    </div>
 </div>
 <!-- Header End -->
    <!-- Destination Start -->
    <div class="container">
        <hr>
        <div class="row">
            <div class="col-sm-4" style="display: flex; align-items: center;">
                <!-- Column Info 1 -->
                <img class="img-thumbnail" src="../../public/img/logo_dicYt.jpg" alt="" style="height: 300px; width: 300px;">
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center text-uppercase">OBJETIVOS DEL OBSERVATORIO</h2>
                <h5>DEFINICION</h5>
                <p class="text-wrap" style="text-align: justify;" >
                    El Observatorio del Desarrollo de Oruro es una unidad técnica dedicada al resguardo y difusión de información y documentación,planteamiento de mejora de políticas y realización de estudios estratégicos para el desarrollo sostenible de Oruro.
                </p>
                <hr>
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center text-uppercase">OBJETIVOS DEL OBSERVATORIO</h2>
                <h5>PRINCIPIOS</h5>
                <p class="text-wrap" style="text-align: justify;" >
                   <ul>
                    <li>Imparcialidad</li>
                    <li>Responsabilidad</li>
                    <li>Equidad</li>
                   </ul>
                </p>
                <hr>
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center text-uppercase">OBJETIVOS DEL OBSERVATORIO</h2>
                <h5>OBJETIVO GENERAL</h5>
                <p class="text-wrap" style="text-align: justify;" >
                    Apoyar con información,documentación y propuestas en la toma de decisiones de entidades publicas,privadas y actores del desarrollo sostenible de Oruro
                </p>
                <hr>
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center text-uppercase">OBJETIVOS DEL OBSERVATORIO</h2>
                <h5>OBEJTIVOS ESPECIFICOS</h5>
                <p class="text-wrap" style="text-align: justify;" >
                   <ul>
                    <li>Elaborar documentos con propuesta de mejora politicas.</li>
                    <li>Construir y actualizar permanentemente un repositorio de investigaciones y estudios relacionados con el desarrollo de Oruro,a fin de que sirvan de partida para futuros proyectos,programas o estudios.</li>
                    <li>Realizar Investigaciones y estudios multidisciplinarios estratégicos a demanda de los actores del desarrollo de Oruro.</li>
                    <li>Organizar Espacios de análisis y planteamiento de propuestas para el desarrollo de la región.</li>
                </ul>
                </p>
                <hr>
            </div>
        </div>
        <div class="row" id="myv">
            <div class="col-sm-4" style="display: flex; align-items: center;">
                <!-- Column Info 1 -->
                <img class="img-thumbnail"  src="../../public/img/Mision y Vision.png" alt="" style="height: 300px; width: 300px;">
            </div>
            <div class="col-sm-8">
                <!-- Column Info 1 -->
                <h2 class="text-primary text-center ">Misión y Visión</h2>
                <h5>
                    Misión
                </h5>
                <p class="text-wrap" style="text-align: justify;">
                    Fomentar y realizar investigación, desarrollo e innovación, en la comunidad universitaria de la UTO (docentes y estudiantes), para el crecimiento de la ciencia y conocimiento, mejorando las capacidades profesionales de sus integrantes y para el beneficio del Sistema de la Universidad Boliviana, de los bolivianos y de las organizaciones públicas y privadas de Bolivia, principalmente en las áreas de mayor competencia profesional de la UTO y en las áreas de mayor relevancia.
                </p>
                    <hr>
                <h5>
                    Visión
                </h5>
                <p class="text-wrap" style="text-align: justify;">
                    La UTO fomenta y realiza investigación, desarrollo e innovación de calidad publicada en revistas indexadas, que conduce a la generación de patentes, desarrollo productivo y social y a la formación de profesionales con competencias investigativas y conocimiento científico para el desarrollo económico y social de Oruro y Bolivia.
                    relevancia.
                </p>
                <hr>
            </div>
        </div>
    </div>
    <div class="container-fluid py-5">

        <div class="container pt-5 pb-3">
            <div class="text-center mb-3 pb-3">
                <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">REPOSITORIOS</h6>
                <h1>Filtrar Por Area</h1>
            </div>
            <div class="row">
                                <div class="col-md-3 mb-5">
                                    <div class="mb-3 mb-md-0 mb-5">
                                        <select class="custom-select px-4" ref="area" style="height: 47px;">
                                            <option selected>Area</option>
                                            <option v-for="rep in repositorioArea" :key="rep.id">
                                                <div>
                                                    {{ rep }}
                                                </div>

                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                            <button class="btn btn-primary btn-block" type="button" style="height: 47px; margin-top: -2px;" @click="searchRepositorio()">Buscar</button>
                        </div>
                            </div>
                            <div class="container ">
    <h2 class="text-secondary text-center mb-5 pt-3">MOSTRAR RESULTADOS DE BUSQUEDA</h2>
    <div class="container ">
        <div class="row" v-for="rep in repositorios" :key="rep.id">
            <div class="col-sm-4">
                <GoogleDriveViewer :driveUrl="rep.area" />
            </div>
            <div class="col-sm-8 ps-5">
                <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ rep.nombre }}</strong></a>
                <hr>
            </div>
        </div>
    </div>
</div>

        </div>

        <div class="container pt-5 pb-3">
            <div class="text-center mb-3 pb-3">
                <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">PROPUESTAS PARA POLITICAS  </h6>
                <h1>Recomendaciones para decisores politicos</h1>
            </div>
            <div class="row" v-for="conv in politicas" :key="conv.id">
            <div class="col-sm-4">
                <GoogleDriveViewer :driveUrl="conv.archivo" />
            </div>
            <div class="col-sm-8 ps-5">
                <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ conv.nombre }}</strong></a>
                <hr>
            </div>
        </div>
        </div>

        <div class="container pt-5 pb-3">
            <div class="text-center mb-3 pb-3">
                <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">ACCIONES EN EJECUCION </h6>
                <h1>Estrategias para llevar el proyecto a la linea de meta</h1>
            </div>
            <div class="row" v-for="conv in acciones" :key="conv.id">
            <div class="col-sm-4">
                <GoogleDriveViewer :driveUrl="conv.archivo" />
            </div>
            <div class="col-sm-8 ps-5">
                <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ conv.nombre }}</strong></a>
                <p style="text-align:justify ;">
                    {{conv.proyecto}}
                </p>
                <hr>
            </div>
        </div>
        </div>
    </div>
    <!-- Destination End -->
    </div>
</template>

<script>
    import axios from 'axios'
    import GoogleDriveViewer from '@/components/helpers/GoogleDriveViewer'; // Ruta al componente GoogleDriveViewer.vue
    export default {
        components :{
            GoogleDriveViewer
        },
        data(){
            return {
                repositorioArea:null,
                repositorios:null,
                politicas:null,
                acciones:null
            }
        },
        mounted(){
            this.onLoadRepositorios(),
            this.onLoadPoliticas(),
            this.onLoadAcciones()
        },
        methods:{
            async onLoadRepositorios(){
                const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/repositorio/listado')
                const repositorioArea =data.map(e=>e.area)
                const repArea = new Set(repositorioArea)
                this.repositorioArea=repArea
        },
        async searchRepositorio(){
                const area =this.$refs.area.value
                try{
                    const {data} = await axios.post(process.env.VUE_APP_API_BASE_URL + '/repositorio/buscar',{
                    area
                })
                this.repositorios=data
                }catch(error){
                    console.log(error)
                }
          },
          async onLoadPoliticas(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/propuestas/listado')
            this.politicas=data
          },
          async onLoadAcciones(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/acciones/listado')
            this.acciones=data
          }
        }
}
</script>

<style lang="scss" scoped>

</style>