<template>
    <div>
        
    <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Proyecto de análisis de datos meteorológicos</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/proyectos">Proyectos Ejecucion</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Proyecto de análisis de datos meteorológicos</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

<!-- meteorologia start -->
<div class="container ">
    <div class="container ">
        <div class="row" style="display: flex; flex-direction: column; align-items: center;">
          <h2 class="text-primary text-center mb-5 pt-3 text-uppercase">Datos meteorológicos</h2>    
          <img src="../../../public/img/Meteorologia.gif" alt="">
        </div>
        <div class="row" >
            <h2 class="text-primary text-center mb-5 pt-3 text-uppercase" >Datos de precipitación pluvial acumulada</h2>    
            <h2 class="text-secondary text-md-left">Datos de precitación pluvial acumulada en la ciudad de Oruro-Bolivia</h2>    
            <h3 class="text-secondary text-md-left mb-5 pt-3">Estación meteorológica: Aeropuerto de la ciudad de Oruro (Bolivia)</h3>
            <ul>
                <li>Longitud: -67.079722</li>
                <li>Latitud: -17.952778</li>
                <li>Altura: 3702 msnm</li>
            </ul>
        </div>
    </div>
</div>
<!-- meteorologia end -->
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>