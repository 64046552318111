<template>
    <div>

    <!-- Header Start -->
    <div class="container-fluid page-header">
        <br>
      <br>
      <br>
      <br>
        <div class="container">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Investigación en la uto</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase">
                      <router-link class="text-white" to="/">Inicio</router-link>
                    </p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Investigación en la uto</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->

    <!-- Service Start -->
        <div class="container-fluid py-5">
            <div class="container pt-5 pb-3">
                <div class="text-center mb-3 pb-3">
                    <h6 class="text-primary text-uppercase" style="letter-spacing: 5px;">DICyT</h6>
                    <h1>Investigación</h1>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-4 pb-5" v-for="inv in investigaciones" :key="inv.id">
                        <div class="service-item bg-white text-center mb-2 py-5 px-4">
                            <router-link :to="`/investigacion/${inv.nombre}`"><i class="fas fa-2x fa-newspaper mx-auto mb-4"></i></router-link>
                            <h5 class="mb-2">{{ inv.nombre }}</h5>
                            <p class="m-0">{{ inv.descripcion }}</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb-4 pb-5">
                        <div class="service-item bg-white text-center mb-2 py-5 px-4">
                            <a href="https://postgrado.uto.edu.bo"><i class="fas fa-2x fa-newspaper mx-auto mb-4"></i></a>
                            <h5 class="mb-2">Tesis</h5>
                            <p class="m-0">Tesis de grado</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mb-4 pb-5">
                        <div class="service-item bg-white text-center mb-2 py-5 px-4">
                            <a href="https://postgrado.uto.edu.bo"><i class="fas fa-2x fa-newspaper mx-auto mb-4"></i></a>
                            <h5 class="mb-2">Tesis</h5>
                            <p class="m-0">Tesis de Postgrado</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- Service End -->
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        data(){
            return {
                investigaciones:null
            }
        },
    mounted() {
        this.onLoadInvestigacion()
    },
    methods:{
        async onLoadInvestigacion(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/investigacion_categoria/listado')
            this.investigaciones=data
        }
    }
    }
</script>

<style lang="scss" scoped>

</style>