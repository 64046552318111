<template>
    <div>
         <!-- Carousel Start -->
    <div class="container-fluid p-0">
        <div id="header-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100"  src="../../public/img/portada dicyt 2.jpeg" loading="lazy" alt="Image">
                    <div class="note">
                        Dirección de investigación científica y tecnológica de la uto
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="../../public/img/logo Dicyt portada2.png" loading="lazy" alt="Image">
                    <div class="note">
                        Dirección de investigación científica y tecnológica de la uto
                    </div>
                </div>

                <div class="carousel-item">
                    <img class="w-100" src="../../public/img/portada dicyt 3.png" loading="lazy" alt="Image">
                    <div class="note">
                        Dirección de investigación científica y tecnológica de la uto
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#header-carousel" data-slide="prev">
                <div class="btn btn-dark" style="width: 45px; height: 45px;">
                    <span class="carousel-control-prev-icon mb-n2"></span>
                </div>
            </a>
            <a class="carousel-control-next" href="#header-carousel" data-slide="next">
                <div class="btn btn-dark" style="width: 45px; height: 45px;">
                    <span class="carousel-control-next-icon mb-n2"></span>
                </div>
            </a>
        </div>
    </div>
    <!-- Carousel End -->
    <!--Link Facultades start-->
    <div class="row">
        <div class="col-sm-12 " id="linkFacus">
            <a href="#"><img class="Facultades" src="../../public/img/logo-fcan-150x150.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-FT-300x300.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-Medicina-300x300.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-FNI-273x300.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-fau-150x150.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-derecho-150x150.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-ceub-150x150.png" alt=""></a>
            <a href="#"><img class="Facultades" src="../../public/img/logo-eco-300x300.png" alt=""></a>
        </div>
    </div>
    <!--Link Facultades start-->

    <!-- Convocatorias start -->
    <div class="container ">
        <h2 class="text-primary pb-3 pt-3 text-uppercase">Convocatorias y Eventos</h2>
        <div class="row" v-for="conv in convocatorias" :key="conv.id">
            <div class="col-sm-4">
                <GoogleDriveViewer :driveUrl="conv.imagen" />
            </div>
            <div class="col-sm-8 ps-5">
                <a href="#" style="color: blue; font-size: x-large ;"><strong>{{ conv.nombre }}</strong></a>
                <p style="text-align:justify ;">
                    {{conv.descripcion}}
                </p>
                <hr>
            </div>
        </div>
    <!-- Convocatorias end -->

    <!-- news start -->
    <div class="row">
        <div class="col-sm-12">
            <h2 class="m-4 text-primary text-center text-uppercase" >
                    Noticias
            </h2>
        </div>
          <div class="col-sm-12 m-2" v-for="noticia in noticias" :key="noticia.id">
            <!-- Column Info 1 -->
            <GoogleDriveViewer :driveUrl="noticia.imagen" />
            <h5>
                {{ noticia.nombre }}
            </h5>
            <button class="btn btn-primary"><i class="fa fa-book" aria-hidden="true"></i>  Ingresar</button>
            <hr>
          </div>
        </div>
    <!--news end -->

    <!--courses start -->
        <h2 class="text-secondary text-center mb-5">Cursos</h2>
        <div class="card-deck mb-5">
            <div class="row" >
                <div class="col-sm-4" v-for="curso in cursos" :key="curso.id">
                    <div class="card">
                <h5 class="card-title p-3">{{ curso.nombre }}</h5>
                <GoogleDriveViewer :driveUrl="curso.imagen" />
              <div class="card-footer">
                <button class="btn btn-primary"><i class="fa fa-arrow-right" aria-hidden="true"></i> Información</button>
              </div>
            </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import GoogleDriveViewer from '@/components/helpers/GoogleDriveViewer'; // Ruta al componente GoogleDriveViewer.vue
    import axios from 'axios'
    export default {
        components :{
            GoogleDriveViewer
        },
        data(){
            return {
                driveUrl:'https://drive.google.com/file/d/1frGIV3qioum_lDyQlUZTHxe8t9VS2j8G/view',
                convocatorias:null,
                cursos:null,
                noticias:null
            }
        },
        mounted(){
            this.onLoadConvcatorias()
            this.onLoadCursos()
            this.onLoadNoticias()
        },
        methods:{
            async onLoadConvcatorias(){
           await axios.get(process.env.VUE_APP_API_BASE_URL + '/convocatorias/listadoPrincipal').
            then(res =>{
                const {data}=res
                this.convocatorias=data
            })
        },
        async onLoadCursos(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/cursos/listado')
            this.cursos=data
        },
        async onLoadNoticias(){
            const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + '/noticias/listado')
            this.noticias=data
        }
        }
    }
</script>
<style scoped>

</style>
