<template>
    <div>
          <!-- Header Start -->
    <div class="container-fluid page-header">
        <div class="container mt-5">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <h3 class="display-4 text-white text-uppercase text-center">Proyectos en ejecución</h3>
                <div class="d-inline-flex text-white">
                    <p class="m-0 text-uppercase"><router-link to='/' class="text-white">Inicio</router-link></p>
                    <i class="fa fa-angle-double-right pt-1 px-3"></i>
                    <p class="m-0 text-uppercase text-center">Proyectos en ejecución</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->
         <!-- Blog Start -->
    <div class="container-fluid py-5">
        <div class="container py-5">
            <h2 class="text-primary text-center pt-3 pb-3" >Proyectos en ejecución</h2>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row pb-3">
                        <div class="col-md-6 mb-4 pb-2" v-for="proy in ProyectosEjecucuion" :key="proy.id">
                            <div class="blog-item">
                                <div class="position-relative">
                                    <GoogleDriveViewer :driveUrl="proy.imagen" class="img-fluid w-100"  />
                                </div>
                                <div class="bg-white p-4">
                                    <div class="d-flex mb-2">
                                        <router-link :to="`/proyectos/${proy.tag_proyecto}`"><i class="text-primary text-uppercase text-decoration-none"></i>
                                            Mayor Información <i class="fas fa-external-link-alt"></i>
                                        </router-link>
                                    </div>
                                    <p class="h5 m-0 text-decoration-none" >{{proy.nombre}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 mt-5 mt-lg-0">
                    <!-- Author Bio -->
                    <div class="d-flex flex-column text-center bg-white mb-5 py-5 px-4">
                        <img src="img/logo_dicYt.jpg" class="img-fluid mx-auto mb-3" style="width: 100px;">
                        <h3 class="text-primary mb-3">DICyT</h3>
                        <p>Dirección de Investigación Cientifica y Tecnologica</p>
                    </div>

                    <!-- Category List -->
                    <!-- <div class="mb-5">
                        <h4 class="text-uppercase mb-4" style="letter-spacing: 5px;">Categories</h4>
                        <div class="bg-white" style="padding: 30px;">
                            <ul class="list-inline m-0">
                                <li class="mb-3 d-flex justify-content-between align-items-center">
                                    <a class="text-dark" href="#"><i class="fa fa-angle-right text-primary mr-2"></i>Web
                                        Design</a>
                                    <span class="badge badge-primary badge-pill">150</span>
                                </li>
                                <li class="mb-3 d-flex justify-content-between align-items-center">
                                    <a class="text-dark" href="#"><i class="fa fa-angle-right text-primary mr-2"></i>Web
                                        Development</a>
                                    <span class="badge badge-primary badge-pill">131</span>
                                </li>
                                <li class="mb-3 d-flex justify-content-between align-items-center">
                                    <a class="text-dark" href="#"><i
                                            class="fa fa-angle-right text-primary mr-2"></i>Online Marketing</a>
                                    <span class="badge badge-primary badge-pill">78</span>
                                </li>
                                <li class="mb-3 d-flex justify-content-between align-items-center">
                                    <a class="text-dark" href="#"><i
                                            class="fa fa-angle-right text-primary mr-2"></i>Keyword Research</a>
                                    <span class="badge badge-primary badge-pill">56</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <a class="text-dark" href="#"><i
                                            class="fa fa-angle-right text-primary mr-2"></i>Email Marketing</a>
                                    <span class="badge badge-primary badge-pill">98</span>
                                </li>
                            </ul>
                        </div>
                    </div> -->

                    <!-- Recent Post -->
                    <!-- <div class="mb-5">
                        <h4 class="text-uppercase mb-4" style="letter-spacing: 5px;">Recent Post</h4>
                        <a class="d-flex align-items-center text-decoration-none bg-white mb-3" href="">
                            <img class="img-fluid" src="img/blog-100x100.jpg" alt="">
                            <div class="pl-3">
                                <h6 class="m-1">Diam lorem dolore justo eirmod lorem dolore</h6>
                                <small>Jan 01, 2050</small>
                            </div>
                        </a>
                        <a class="d-flex align-items-center text-decoration-none bg-white mb-3" href="">
                            <img class="img-fluid" src="img/blog-100x100.jpg" alt="">
                            <div class="pl-3">
                                <h6 class="m-1">Diam lorem dolore justo eirmod lorem dolore</h6>
                                <small>Jan 01, 2050</small>
                            </div>
                        </a>
                        <a class="d-flex align-items-center text-decoration-none bg-white mb-3" href="">
                            <img class="img-fluid" src="img/blog-100x100.jpg" alt="">
                            <div class="pl-3">
                                <h6 class="m-1">Diam lorem dolore justo eirmod lorem dolore</h6>
                                <small>Jan 01, 2050</small>
                            </div>
                        </a>
                    </div>
     -->
                    <!-- Tag Cloud -->
                    <!-- <div class="mb-5">
                        <h4 class="text-uppercase mb-4" style="letter-spacing: 5px;">Tag Cloud</h4>
                        <div class="d-flex flex-wrap m-n1">
                            <a href="" class="btn btn-light m-1">Design</a>
                            <a href="" class="btn btn-light m-1">Development</a>
                            <a href="" class="btn btn-light m-1">Marketing</a>
                            <a href="" class="btn btn-light m-1">SEO</a>
                            <a href="" class="btn btn-light m-1">Writing</a>
                            <a href="" class="btn btn-light m-1">Consulting</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Blog End -->
    </div>
</template>

<script>
    import axios from 'axios';
    import GoogleDriveViewer from "@/components/helpers/GoogleDriveViewer"; // Ruta al componente GoogleDriveViewer.vue
    export default {
        components: {
    GoogleDriveViewer,
  },
        data(){
            return {
                ProyectosEjecucuion:null
            }
        },
        mounted(){
            this.onLoadProyectosEjecucion()
        },
        methods:{
            async onLoadProyectosEjecucion(){
                const {data}= await axios.get(process.env.VUE_APP_API_BASE_URL + "/proyecto_ejecucion/listado")
                this.ProyectosEjecucuion=data
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>