<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Actividades
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Actividades
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model=actividad.nombre>
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen 1</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=actividad.imagen1>

                                    </div>
                                    <div class="form-group">
                                        <label>Imagen 2</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=actividad.imagen2>

                                    </div>
                                    <div class="form-group">
                                        <label>Imagen 3</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=actividad.imagen3>

                                    </div>
                                    <div class="form-group">
                                        <label>Tipo</label>
                                        <select id="options" class="form-control" v-model="actividad.tipo">
                                        <option value="guardana">Guardaña</option>
                                        <option value="sulloma">Sulloma</option>
                                        <option value="fluvial">Agua Fluvial</option>
                                        <option value="residual">Agua Residual</option>
                                        </select>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Descripcion</label>
                                        <textarea class="form-control" placeholder="Ingresa la Descripcion" v-model="actividad.descripcion"> </textarea>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveActividad()">Registrar</button>
                                        <button class="btn btn-danger" @click="backConvocatoria()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            actividad:{
                nombre:'',
                descripcion:'',
                imagen1:"",
                imagen2:"",
                imagen3:"",
                tipo:''
            }
        }
    },
    methods:{
        async saveActividad(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/actividades/crear',this.actividad).
            then(res =>console.log(res))
            this.$router.push('/admin/index-actividades');
        },
        backConvocatoria(){
        this.$router.push('/admin/index-actividades');
    }
    }
}
</script>
