<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Registrar Cursos
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Cursos
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model=curso.nombre>
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model=curso.imagen>

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Descripcion</label>
                                        <textarea class="form-control" placeholder="Ingresa la Descripcion" v-model="curso.descripcion"> </textarea>
                                    </div>
                                    </div>
                                </div>
                                        <button class="btn btn-primary" @click="saveCurso()">Registrar</button>
                                        <button class="btn btn-danger" @click="backCurso()">Cancelar</button>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            curso:{
                nombre:'',
                descripcion:'',
                imagen:""
            }
        }
    },
    methods:{
        async saveCurso(){
            await axios.post(process.env.VUE_APP_API_BASE_URL + '/cursos/crear',this.curso).
            then(res =>console.log(res))
            this.$router.push('/admin/index-cursos');
        },
        backCurso(){
        this.$router.push('/admin/index-cursos');
    }
    }
}
</script>
