<template>
  <div>
    <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div>
        <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
        <h1>
          Editar Noticias
        </h1>
      </div>
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Formulario de Registro de Noticias
                    </div>
                    <div class="panel-body">
                            <form role="form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Nombre</label>
                                        <input class="form-control" placeholder="Ingresa Nombre Convocatoria" v-model="noticiasEdit.nombre">
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen</label>
                                        <input type="text" class="form-control" placeholder="Ingresa Enlace de Drive" v-model="noticiasEdit.imagen">

                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <label>Descripcion</label>
                                        <textarea class="form-control" placeholder="Ingresa la Descripcion" v-model="noticiasEdit.descripcion"> </textarea>
                                    </div>
                                    </div>
                                </div>
                                </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveEditNoticia">Guardar Cambios</button>
      </div>
    </div>
  </div>
</div>
    </div>




  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h1>
          Listado Noticias
          <router-link to="/admin/create-noticias"
            ><button class="btn btn-success">Nueva Noticia </button></router-link
          >
        </h1>
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="panel panel-default">
          <div class="panel-heading">Gestion y Registro de Noticias</div>
          <!-- /.panel-heading -->
          <div class="panel-body">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered table-hover"
                id="dataTables-example"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Imagen</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody v-for="noticia in noticias" :key="noticia.id">
                  <tr>
                   <td>{{noticia.id}}</td>
                   <td>{{noticia.nombre}}</td>
                   <td>{{ noticia.descripcion }}</td>
                   <td>{{ noticia.imagen }}</td>
                    <td class="center">
                      <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal" @click='editNoticias(noticia.id)'>
                        Editar


                      </button>
                      <button class="btn btn-danger" @click="deleteNoticias(noticia.id)">
                      Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.table-responsive -->
          </div>
          <!-- /.panel-body -->
        </div>
        <!-- /.panel -->
      </div>
      <!-- /.col-lg-12 -->
    </div>
    <!-- /.row -->
    <!-- /.container-fluid -->
  </div>

  </div>

</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            noticias:null,
            noticiasEdit:{}
        }
    },
    mounted(){
        this.onLoadNoticias()
    },
    methods:{
        async onLoadNoticias(){
           await axios.get(process.env.VUE_APP_API_BASE_URL + '/noticias/listado').
            then(res =>{
                const {data}=res
                this.noticias=data
            })
        },
        async deleteNoticias(id){
       await axios.delete(process.env.VUE_APP_API_BASE_URL + '/noticias/eliminar',{
        data:{id}
      })
        this.onLoadNoticias()
    },
    async editNoticias(id){
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/noticias/mostrar/'+id).then(res =>{
                const {data}=res
                this.noticiasEdit=data[0]
            })
    },
    async saveEditNoticia(){
      const {data}=await axios.put(process.env.VUE_APP_API_BASE_URL + '/noticias/actualizar',this.noticiasEdit)
      console.log(data);
      this.onLoadNoticias()
    }
    },


}
</script>

