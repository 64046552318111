<template>
    <div>
      <!-- Header Start -->
      <div class="container-fluid page-header">
        <br />
        <br />
        <br />
        <br />
        <div class="container">
          <div
            class="d-flex flex-column align-items-center justify-content-center"
            style="min-height: 400px"
          >
            <h3 class="display-4 text-white text-uppercase text-center">
                Publicación de datos de proyectos de Sociedades Cientificas
            </h3>
            <div class="d-inline-flex text-white">
              <p class="m-0 text-uppercase">
                        <router-link class="text-white" to="/">Inicio</router-link>
                      </p>
                      <i class="fa fa-angle-double-right pt-1 px-3"></i>
              <i class="fa fa-angle-double-right pt-1 px-3"></i>
              <p class="m-0 text-uppercase text-center">
                Publicación de datos de proyectos de Sociedades Cientificas
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Header End -->
      <div class="container-fluid booking mt-5 pb-5">
              <div class="container pb-5">
                  <div class="bg-light shadow" style="padding: 30px;">
                      <div class="row align-items-center" style="min-height: 60px;">
                          <div class="col-md-10">
                              <div class="row">
                                  <div class="col-md-3">
                                      <div class="mb-3 mb-md-0">
                                          <select class="custom-select px-4" ref="facultad" style="height: 47px;">
                                              <option selected>Facultad</option>
                                              <option v-for="inv in sociedadFacultad" :key="inv.id">
                                                  <div>
                                                      {{ inv }}
                                                  </div>

                                              </option>
                                          </select>
                                      </div>
                                  </div>
                                  <div class="col-md-3">
                                      <div class="mb-3 mb-md-0">
                                          <select class="custom-select px-4" ref="carrera" style="height: 47px;">
                                              <option selected>Carrera</option>
                                              <option v-for="inv in sociedadCarrera" :key="inv.div">
                                                  <div>
                                                      {{ inv }}
                                                  </div>

                                              </option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-2">
                              <button class="btn btn-primary btn-block" type="button" style="height: 47px; margin-top: -2px;" @click="searchInvestigador()">Buscar</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      <div class="container">
        <div class="row">
          <h2 class="text-secondary text-center mb-5 pt-3">SOCIEDAD</h2>
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-md-4 col-sm-6 pb-2"
            v-for="inv in SociedadesCientificas"
            :key="inv.id"
          >
            <div class="team-item bg-white mb-4">
              <!-- <div class="team-img position-relative overflow-hidden">
                <GoogleDriveViewer
                  :driveUrl="inv.foto_investigador"
                  class="img-fluid w-100"
                />
              </div> -->
              <div class="text-center pb-5 pt-5">
                <h5 class="text-truncate" style="text-wrap: wrap">
                  {{ inv.nombre_soce }}
                </h5>
                <br />
                <p class="m-0">{{ inv.facultad }}</p>
                <p class="m-0">{{ inv.carrera }}</p>
                <p class="m-0">{{ inv.universitarios }}</p>
                <p class="m-0 ">{{ inv.cargo }}</p>
                <p class="m-0 ">
                    PROYECTOS REALIZADOS
                </p>
                <p class="m-0">
                  {{ inv.proyecto_nombre }}
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style scoped>
    ul{
      padding-left:0px;
    }
  </style>

  <script>
  import axios from "axios";
  // import GoogleDriveViewer from "@/components/helpers/GoogleDriveViewer"; // Ruta al componente GoogleDriveViewer.vue
  export default {
    // components: {
    //   GoogleDriveViewer,
    // },
    data() {
      return {
        SociedadesCientificas: null,
        sociedadFacultad:null,
        sociedadCarrera:null,
        proyectoSoce: null
      };
    },
    mounted() {
      this.onLoadTrabajoInvestigacion();
      this.onLoadArticulos();
    },
    methods: {
      async onLoadTrabajoInvestigacion() {
        const { data } = await axios.get(
          process.env.VUE_APP_API_BASE_URL + "/sociedad/listadoView"
        );
                  const investigacionFac =data.map(e=>e.facultad)
                  const investigacionCar =data.map(e=>e.carrera)
                  const sociedadFacultad = new Set(investigacionFac)
                  const sociedadCarrera= new Set(investigacionCar)
                  this.sociedadFacultad=sociedadFacultad
                  this.sociedadCarrera=sociedadCarrera
      },
      async onLoadArticulos() {
        const { data } = await axios.get(
          process.env.VUE_APP_API_BASE_URL + "/proyecto-soce/listado"
        );
        this.proyectoSoce = data;
      },
      async searchInvestigador(){
                  const facultad =this.$refs.facultad.value
                  const carrera =this.$refs.carrera.value
                  const {data} = await axios.post(process.env.VUE_APP_API_BASE_URL + '/sociedad/search',{
                      facultad,
                      carrera
                  })
                  console.log(data)
                  this.SociedadesCientificas=data


      }
    },
  };
  </script>
